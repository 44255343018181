import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, Card } from 'react-bootstrap';
import Cookies from "js-cookie";

export default function PracticeTestsList() {
  const navigate = useNavigate();
  const [practiceTests, setPracticeTests] = useState([]);

  useEffect(() => {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;

    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`,
      },
    };

    axios.get(process.env.REACT_APP_BACKEND_API_URL + '/get-all-practice-tests/', config)
      .then(response => {
        setPracticeTests(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleRowClick = (id) => {
    navigate('/testresults', { state: { id } });
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title as="h2">Practice Tests</Card.Title>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Child</th>
              <th>Subject</th>
              <th>Test Date</th>
              <th>Percentage</th>
              <th>Score</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            {practiceTests.map((test, index) => (
              <tr key={index} onClick={() => handleRowClick(test.id)} style={{ cursor: 'pointer' }}>
                <td>{test.id}</td>
                <td>{test.child_name}</td>
                <td>{test.subject}</td>
                <td>{new Date(test.test_date).toLocaleString()}</td>
                <td>{((test.score / test.total_score) * 100).toFixed(1)}%</td>
                <td>{test.score}</td>
                <td>{test.total_score}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
