import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Container, FloatingLabel, Alert } from "react-bootstrap";
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom";
import SuccessMessage from "../Static/SuccessMessage";
import TickCard from "../Static/TickCard";
import { useLoading } from "../Static/LoadingContext";
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import { Toast, ToastContainer } from "react-bootstrap";


const SignIn = (props) => {
  const initialValues = { username: "", password: "" };
  const [signinValues, setSigninValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [checked, setChecked] = useState(true); // Automatically apply transition on page load

  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const message = location?.state?.message;
  const success = props.success;
  const verificationmessage = props.verificationmessage;
  const [showToast, setShowToast] = useState(false);
const [toastMessage, setToastMessage] = useState("");
const [toastVariant, setToastVariant] = useState("success");

useEffect(() => {
  if (message) {
    setToastMessage(message);
    setToastVariant("success");
    setShowToast(true);
  }
  if (success === true) {
    setToastMessage(verificationmessage);
    setToastVariant("success");
    setShowToast(true);
  }
}, [message, success, verificationmessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "username" ? value.toLowerCase() : value;
    setSigninValues((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    setFormErrors((prevState) => {
      const errors = { ...prevState };
      delete errors[name];
      return errors;
    });
  };

  const storeAuthToken = (token, userType, children, firstName) => {
    return new Promise((resolve, reject) => {
      if (token && userType) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userType', userType);
        localStorage.setItem('firstName', firstName);
        if (userType === "parent") {
          localStorage.setItem('children', JSON.stringify(children));
        }
        resolve();
      } else {
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem('authToken');
      }
    });
  };

  const handleSubmit = (event) => {
    const errors = validate(signinValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_API_URL + "/login/", signinValues)
        .then((response) => {
          const authToken = response.data.token;
          const userType = response.data.userType;
          const children = response.data.children;
          const firstName = response.data.firstName;
          storeAuthToken(authToken, userType, children, firstName).then(() => {
            if (userType === "child") {
              navigate("/ppstartpage");
            } else if (userType === "parent") {
              navigate("/home", { state: { userType, children } });
            } else if (userType === "qbAdmin" || userType === "super admin") {
              navigate("/addquestions");
            } else if (userType === "qbDeveloper") {
              navigate("/mathsquestions");
            }
          });
        })
        .catch((error) => {
          if (error.response == undefined) {
            setErrorMessage("We are currently facing technical issue. Please try again later. (Error code " + error.code + ")");
          }
          else if (error.response.status === 401) {
            setErrorMessage(error.response.data.detail);
          } else if (error.response.status === 500) {
            setErrorMessage("Something went Wrong. Please try again.");
          }
          else{
            setErrorMessage(error.response.data);
          }
        })
        .finally(() => {
          setIsSubmit(false);
          setIsLoading(false);
        });
    } else {
      setIsSubmit(false);
    }
  }, [formErrors, isSubmit, signinValues]);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userType = localStorage.getItem('userType');
    const children = JSON.parse(localStorage.getItem("children"));
    const firstName = localStorage.getItem("firstName");
    storeAuthToken(authToken, userType, children, firstName).then(() => {
      if (userType === "child") {
        navigate("/ppstartpage");
      } else if (userType === "parent") {
        navigate("/home", { state: { userType, children } });
      } else if (userType === "qbAdmin" || userType === "super admin") {
        navigate("/addquestions");
      }
      else if (userType === "qbDeveloper") {
        navigate("/mathsquestions");
      }
    });
  }, []);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (values.username === "") {
      errors.username = "Username cannot be empty";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 8 characters or above";
    }
    return errors;
  };

  return (
    <>

      <Container
        className="bg-light"
        fluid
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        
        <Row className="d-flex align-items-stretch">
          <Col md={2} className="d-none d-lg-block"></Col>
          <Col md={4} className="d-lg-block d-md-block d-none mt-4 d-flex flex-column justify-content-center flex-grow-1">
            <Grow in={checked}>
              <div>
                <h2 className="mb-4 text-center"><strong>Welcome to Practice Papers</strong></h2>
                <TickCard color="green" text="Comprehensive practice tests: Access thousands of expertly designed questions for GL and CEM style UK 11+ exams across all subjects." />
                <TickCard color="green" text="Instant marking system: Receive real-time feedback and detailed explanations for incorrect answers, helping students learn from their mistakes." />
                <TickCard color="green" text="Customizable practice sessions: Focus on specific subjects or question types, tailoring your practice experience to suit individual needs." />
                <TickCard color="green" text="Progress tracking: Monitor your performance, identify areas for improvement, and build confidence as you work through our practice tests." />
                <TickCard color="green" text="Varied difficulty levels: Catering to students of all abilities, our platform offers a range of difficulty levels for a personalized learning experience." />
              </div>
            </Grow>
          </Col>
          <Col xs={12} md={6}>
            <Grow in={checked}>
              <Card className="mt-4" style={{ maxWidth: "600px", margin: "0 auto" }}>
                <Card.Body>
                  <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5 text-center">Sign In</h3>
                  {errorMessage && <Alert key="danger" variant="danger">{errorMessage}</Alert>}
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="form3">
                        <FloatingLabel label="Username">
                          <Form.Control size="md"
                            type="text"
                            name="username"
                            value={signinValues.username}
                            onChange={handleChange}
                            isInvalid={formErrors.username}
                            onKeyDown={handleEnterKeyPress}
                            id="username"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formErrors.username}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-2" controlId="form4">
                        <FloatingLabel label="Password">
                          <Form.Control size="md"
                            type="password"
                            name="password"
                            value={signinValues.password}
                            onChange={handleChange}
                            isInvalid={formErrors.password}
                            onKeyDown={handleEnterKeyPress}
                            id="password"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formErrors.password}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Button onClick={handleSubmit} className="mb-3 mt-4" size="lg" variant="primary" block>
                      Sign In
                    </Button>
                    <p className="text-center">
                      Forgot password? <Link to="/forgotpassword">Retrieve Here</Link>
                    </p>
                  </Row>
                  <hr className="my-4" />
                  <p className="text-center">
                    Don't have an account? <Link to="/parentguardiansignup">Sign Up Here</Link>
                  </p>
                </Card.Body>
              </Card>
            </Grow>
          </Col>
        </Row>
      </Container>
      <ToastContainer position="top-end" className="p-3" style={{"marginTop": "5vh"}}>
  <Toast 
    show={showToast} 
    onClose={() => setShowToast(false)} 
    delay={5000} 
    autohide 
    bg={toastVariant}
    color="light"
  >

    <Toast.Body>  {toastMessage}</Toast.Body>
  </Toast>
</ToastContainer>
    </>
  );
};

export default SignIn;
