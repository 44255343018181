import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { setResponseData } from "./ppStartPageSlice";
import withLoader from "../Static/withLoader";

const PPStartPage = () => {

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`
      }
    };

    // Fetch unsubmitted exams status
     axios.get(process.env.REACT_APP_BACKEND_API_URL + "/practice-test-check-active-session/", config)
      .then(response => {
        setMessage(response.data.unsubmitted)
        console.log(message)
      })
      .catch(error => {
        console.error("There was an error checking unsubmitted exams!", error);
      });
  }, []);

  useEffect(() => {
    console.log(message);
  }, [message])

  const [PTInput, setPTInput] = useState({
    subject: "",
    noofquestion: 0,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const options = [
    { value: 'Fractions', label: 'Fractions' },
    { value: 'Numbers', label: 'Numbers' },
    { value: 'Geometry', label: 'Geometry' },
    { value: 'Measurement', label: 'Measurement' },
    { value: 'Percentages', label: 'Percentages' },
    { value: 'Decimals', label: 'Decimals' },
    { value: 'Arithmetic', label: 'Arithmetic' },
    { value: 'Algebra', label: 'Algebra' },
    { value: 'Numbers', label: 'Numbers' }, 
    { value: 'PlaceValues', label: 'Place Values' },
    { value: 'Ratios', label: 'Ratios' },
    { value: 'Proportions', label: 'Proportions' },
  ];
  const topic = options.sort((a, b) => a.label.localeCompare(b.label));
  const DIFFCULTYLEVEL = [
    {value:'Level 1', label:'Level 1'},
    {value:'Level 2',label: 'Level 2'},
    { value:'Level 3', label:'Level 3'},
    { value:'Level 4', label:'Level 4'},
    { value:'Level 5', label:'Level 5'},
    { value:'Level 6', label:'Level 6'},
    { value:'Level 7', label:'Level 7'},
    { value:'Level 8', label:'Level 8'},
]
const ACADEMICYEAR = [
  { value:'Year 3',label: 'Year 3'},
  { value:'Year 4', label:'Year 4'},
  { value:'Year 5', label:'Year 5'},
  { value:'Year 6', label:'Year 6'},
  { value:'Year 7', label:'Year 7'},
  { value:'Year 8', label:'Year 8'},
  { value:'Year 9', label:'Year 9'},
  { value:'Year 10',label: 'Year 10'},
]
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState([]);
  // console.log(selectedTopics)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onInputChange = (e) => {
    const { name, value, id } = e.target;
    
      setPTInput((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
 
  };
  const handleSelectedTopics = (selectedTopics) => {
  setSelectedTopics(selectedTopics)
  };
  const handleSelectedDL = (selectedDL) => {
    setSelectedDifficultyLevel(selectedDL)
    };
    const handleSelectedAL = (selectedAL) => {
      setSelectedAcademicYear(selectedAL)
      };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleAdvanceChange = () => {
    setIsAdvanced(!isAdvanced);
  };
  const token = localStorage.getItem("authToken")
  const config = {
    headers: { Authorization: `Token ${token}` }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingText('Please wait while we create a Practice Test for you...');
    setIsLoading(true);
   const  topicstring = selectedTopics.map((option) => option.value);
    const data = {
      num_questions: PTInput.noofquestion,
      subject: PTInput.subject, 
      topic: topicstring.join(","),
      difficultyLevel: selectedDifficultyLevel.map((option) => option.value),
      academicYear: selectedAcademicYear.map((option) => option.value),
    };
    
    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/practice-test/", data,config)
      .then((response) => {

         localStorage.setItem("responseData", JSON.stringify(response.data));
         localStorage.removeItem("data");
         setIsLoading(false);
         navigate("/practicetest");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleProceedToExam = (e) => {
    e.preventDefault();
    const  topicstring = selectedTopics.map((option) => option.value);
    const data = {
      "unsubmitted": true,
    };
    // console.log(data);
    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/practice-test/", data,config)
      .then((response) => {
        // console.log(response);
        // dispatch(setResponseData(response.data)); // dispatch the action with response data
         localStorage.setItem("responseData", JSON.stringify(response.data));
         console.log("saved answers", JSON.stringify(response.data.answered_questions))
         localStorage.setItem("data", JSON.stringify(response.data.answered_questions))
         navigate("/practicetest");
      })
      .catch((error) => {
        // console.log(error);
      });

  }
  if (message) {
    return (
      <Container style={{ minHeight: "100vh" }} className="mt-2">
        <Card>
          <Card.Header as="h4" style={{ textAlign: "center", justifyContent: "center" }}>
            Unsubmitted Exam Found
          </Card.Header>
          <Card.Body>
            <p>You have an unsubmitted exam. Please proceed to the exam, submit and view results.</p>
            <Button onClick= {(e) => handleProceedToExam(e)} className="w-100" size="lg">
              Proceed to Exam
            </Button>
            {/* <Button onClick={() => navigate('/results-page')} className="w-100 mt-2" size="sm">
              Submit and View Results
            </Button> */}
          </Card.Body>
        </Card>
      </Container>
    );
  }
  else{
  return (
    <>

      <Container  style={{ minHeight: "100vh"}} className="mt-2" >
        <Card>
          <Card.Header as="h4" style={{ textAlign: "center", justifyContent: "center" }}>
            Select subject and number of questions
          </Card.Header>
          <Card.Body>
            <Row className="mt-4">
              <Col md={3}>

              </Col>
              <Col md={3}>
                <Form.Group controlId="subject">
                  <Form.Select
                    style={{ fontSize: "14px" }}
                    onChange={(e) => onInputChange(e)}
                    name="subject"
                  >
                    <option value="">Select Subject Type</option>
                    <option value="Maths">Maths</option>
                    <option value="English">English</option>
                    <option value="VR">VR</option>
                    {/* <option value="NVR">NVR</option> */}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="noofquestion">
                  <Form.Select
                    style={{ fontSize: "14px" }}
                    onChange={(e) => onInputChange(e)}
                    name="noofquestion"
                  >
                    <option value="">Select No of Questions</option>
                    {/* <option value="3">3</option> */}
                    <option value="25">25</option>
                    {/* <option value="40">40</option>
                    <option value="60">60</option> */}
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* <Col md={3}>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Advance Search"
                    id="advanceSearch"
                    name="advanceSearch"
                    value="advanceSearch"
                    onChange={handleAdvanceChange}
                    checked={isAdvanced}
                  />
                </Form.Group>
              </Col>
              {isAdvanced === true &&
              <Row className="mt-4">
                <Col md={2}></Col>
                <Col md={3}>
                  <Form.Group>
                    <Select
                      name="topics"
                      options={topic}
                      isMulti
                      value={selectedTopics}
                      onChange={handleSelectedTopics}
                      placeholder="select topics"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Select
                      options={DIFFCULTYLEVEL}
                      isMulti
                      value={selectedDifficultyLevel}
                      onChange={handleSelectedDL}
                      placeholder="select Difficulty Level"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Select
                      options={ACADEMICYEAR}
                      isMulti
                      value={selectedAcademicYear}
                      onChange={handleSelectedAL}
                      placeholder="select Academic Year"
                    />
                  </Form.Group>
                </Col>
                <Col md={1}></Col>
              </Row>
              } */}
            </Row>
            {(PTInput.noofquestion != "" && PTInput.subject != "") &&
              <Row className="mt-4">
                <Col md={3}></Col>
                <Col md={6}>
                  <Card>
                    <Card.Header as="h5">
                      Instructions
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <p>
                        <b>Prepare your tools</b>: Keep a paper and pencil handy for any necessary calculations or workings during the practice test.
                        </p>
                        <p>
                        <b>Time management</b>: Be aware of the timer displayed on-screen, which tracks the remaining time for the test, and pace yourself accordingly. The timer starts as soon as the test is displayed. 
                        </p>
                        <p>
                        <b>Automatic submission</b>: Test will be submitted automatically once the timer runs out. Feel free to submit the test if you complete it sooner. 
                        </p>
                        <p>
                        <b>Skipping questions</b>: Feel free to skip questions that you find difficult or time-consuming, and return to them later if time permits.
                        </p>
                        <p>
                        <b>Mark for review</b>: Utilise the 'mark for review' feature to flag questions you would like to revisit before the test ends.
                        </p>
                        <p>
                        <b>Stay focused</b>: Concentrate on the test and apply your knowledge and skills to answer questions to the best of your ability. Good luck!
                        </p>
                        <p style={{textAlign:"center"}}>
                        Click on <b>"Get Practice Test"</b> when you are ready
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}></Col>
              </Row>
            }
            {(PTInput.noofquestion != "" && PTInput.subject != "") &&
              <Row className="mt-3">
                <Col md={3}></Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Please check that you understood the instructions above"
                      id="testinstructions"
                      name="testinstructions"
                      value="testinstructions"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}></Col>
              </Row>
            }
            {(PTInput.noofquestion != "" && PTInput.subject != "") &&
              <Row className="mt-3">
                <Col md={3}></Col>
                <Col md={6}>
                  <Button
                    disabled={!isChecked}
                    onClick={handleSubmit}
                    className="w-100"
                    size="lg"
                    onContextMenu={handleContextMenu}
                  >
                    Get Practice Test
                  </Button>
                </Col>
                <Col md={3}></Col>
              </Row>
            }
          </Card.Body>
        </Card>

      </Container>

      {/* Loader display */}
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader">
            <p>{loadingText}</p>
            <div className="loader-icon"></div>
          </div>
          <style jsx="true">{`
            .loader-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 9999;
            }
            .loader {
              text-align: center;
              padding: 20px;
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.7); /* Darker background for the loader */
              color: white; /* Text color */
            }
            .loader p {
              margin: 0;
              font-size: 1.2em; /* Adjust the font size */
            }
            .loader-icon {
              margin-top: 10px;
              width: 50px;
              height: 50px;
              border: 5px solid #f3f3f3; /* Light grey */
              border-top: 5px solid #3498db; /* Blue */
              border-radius: 50%;
              animation: spin 1s linear infinite;
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
        </div>
      )}
    </>
  );
}

  // else{
  // return <PracticeTest responseData={responseData}/>
  // }
};


export default withLoader(PPStartPage);
