import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { useLoading } from "../Static/LoadingContext";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import useSignOut from "../Static/utils";

const ChildSignUp = () => {
  const SignOut = useSignOut();

  useEffect(() => {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
  }, []);
  const token = localStorage.getItem("authToken");
  const { setIsLoading } = useLoading();

  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  const navigate = useNavigate();
  const [usernameTooltipOpen, setUsernameTooltipOpen] = useState(false);
  const [dobTooltipOpen, setDobTooltipOpen] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const [lastIndex, setLastIndex] = useState();
  
  const usernameTooltip = (
    <Tooltip id="username-tooltip">
      Enter your child's username here.
    </Tooltip>
  );
  const dobTooltip = (
    <Tooltip id="email-tooltip">
      Enter your child's Date of Birth here.
    </Tooltip>
  );

  const initialValues = {
    user: {
      username: "",
      password: "",
      confirmpassword: "",
    },
    firstName: "",
    lastName: "",
    dob: "",
  };

  const storeAuthToken = (message, children) => {
    return new Promise((resolve) => {
      localStorage.removeItem('children');
      localStorage.setItem('children', JSON.stringify(children));
      setLastIndex(children.length - 1);
      resolve();
    })


  }

  const [chSignupFormData, setChSignupFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setChSignupFormData((prevState) => {
      if (name === "username" || name === "password" || name === "confirmpassword") {
        return {
          ...prevState,
          user: {
            ...prevState.user,
            [name]: value,
          },
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    setFormErrors(validate(chSignupFormData));
    event.preventDefault();

    const errors = validate(chSignupFormData);
    if (Object.keys(errors).length === 0) {
      const data = {
        user: {
          username: chSignupFormData.user.username,
          password: chSignupFormData.user.password,
          confirm_password: chSignupFormData.user.confirmpassword,
          first_name: chSignupFormData.firstName,
          last_name: chSignupFormData.lastName,
        },
        dob: chSignupFormData.dob,
      };
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_BACKEND_API_URL + "/register/child/", data, config)
        .then((response) => {
          if (response.status === 201) {
            const message = response.data.message;
            const children = response.data.children;
            setIsSignupSuccess(true);
            storeAuthToken(message, children);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.user["username"]) {
              setSubmitErrors([error.response.data.user["username"][0]]);
            } else if (error.response.data.user) {
              setSubmitErrors([error.response.data.user]);
            }
          } else if (error.response === undefined) {
            setSubmitErrors(["Unable to submit, please try again later."]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSubmitErrors(errors);
    }
  };

  const validate = (values) => {
    const errors = [];
    const today = new Date();
    const dob = new Date(values.dob);
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(today.getFullYear() - 11, today.getMonth() - 10);
    maxDate.setFullYear(today.getFullYear() - 7, today.getMonth() - 5);

    if (!values.firstName) {
      errors.push("First Name is required.");
    } else if (values.firstName.length > 20) {
      errors.push("First Name cannot exceed more than 20 characters.");
    }

    if (!values.lastName) {
      errors.push("Last Name is required.");
    } else if (values.lastName.length > 20) {
      errors.push("Last Name cannot exceed more than 20 characters.");
    }

    if (!values.user.username) {
      errors.push("Username is required.");
    }

    if (!values.user.password) {
      errors.push("Password is required.");
    } else if (values.user.password.length < 4) {
      errors.push("Password must be more than 4 characters.");
    } else if (values.user.password.length > 30) {
      errors.push("Password cannot exceed more than 30 characters.");
    }

    if (!values.user.confirmpassword) {
      errors.push("Confirm Password is required.");
    } else if (values.user.password !== values.user.confirmpassword) {
      errors.push("Passwords do not match.");
    }

    if (!values.dob) {
      errors.push("Date of Birth is required.");
    } else if (dob > today) {
      errors.push("Date of Birth cannot be in the future.");
    } else if (dob < minDate || dob > maxDate) {
      errors.push("Child must be between 7 years 5 months and 11 years 10 months old.");
    }

    return errors;
  };

  const handleDashboardNavigation = () => {
    console.log("LastIndex", lastIndex)
    const state = { lastIndex }
    window.location.href = `/home?state=${lastIndex}`;
  };

  return (
    <Container
      fluid
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Card className="mt-4" style={{ maxWidth: "600px", margin: "0 auto" }}>
        <Card.Body>
          {isSignupSuccess ? (
            <>
              <h3 className="fw-bold mb-4" style={{ textAlign: "center" }}>
                Signup Successful!
              </h3>
              <p style={{ textAlign: "center" }}>
                Your child's account has been created successfully. Use the credentials to log in and start practicing tests.
              </p>
              <Row className="mt-4 d-flex justify-content-center align-items-center">
  <Col xs="auto">
    <Button onClick={handleDashboardNavigation} className="mb-1" size="md">
      Go to Dashboard
    </Button>
  </Col>
  <Col xs="auto">
    <Button onClick={SignOut} className="mb-1" size="md">
      Login to child's account
    </Button>
  </Col>
</Row>

            </>
          ) : (
            <>
              <h3 className="fw-bold mb-4" style={{ textAlign: "center" }}>
                Create Your Child's Account
              </h3>
              {submitErrors.length > 0 && (
                <Alert key="danger" variant="danger">
                  <ul>
                    {submitErrors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <Row>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form1">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="firstName"
                      value={chSignupFormData.firstName}
                      onChange={handleChange}
                      required
                      onKeyDown={handleEnterKeyPress}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form2">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="lastName"
                      value={chSignupFormData.lastName}
                      onChange={handleChange}
                      required
                      onKeyDown={handleEnterKeyPress}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form3">
                    <Form.Label style={{ marginRight: "4px" }}>Username</Form.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={usernameTooltip}
                      show={usernameTooltipOpen}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        onMouseEnter={() => setUsernameTooltipOpen(true)}
                        onMouseLeave={() => setUsernameTooltipOpen(false)}
                      />
                    </OverlayTrigger>
                    <Form.Control
                      size="md"
                      type="text"
                      name="username"
                      value={chSignupFormData.user.username}
                      onChange={handleChange}
                      required
                      onKeyDown={handleEnterKeyPress}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label style={{ marginRight: "4px" }}>Date of Birth</Form.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={dobTooltip}
                      show={dobTooltipOpen}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        onMouseEnter={() => setDobTooltipOpen(true)}
                        onMouseLeave={() => setDobTooltipOpen(false)}
                      />
                    </OverlayTrigger>
                    <Form.Control
                      size="md"
                      type="date"
                      name="dob"
                      value={chSignupFormData.dob}
                      onChange={handleChange}
                      required
                      onKeyDown={handleEnterKeyPress}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      size="md"
                      type="password"
                      name="password"
                      value={chSignupFormData.user.password}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      size="md"
                      type="password"
                      name="confirmpassword"
                      value={chSignupFormData.user.confirmpassword}
                      onChange={handleChange}
                      required
                      onKeyDown={handleEnterKeyPress}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formBasicCheckbox" className="mb-2">
                <Form.Check
                  type="checkbox"
                  label="By registering you accept to our terms and conditions."
                  name="tandc"
                  value={chSignupFormData.tandc}
                  onChange={handleChange}
                  required
                  onKeyDown={handleEnterKeyPress}
                />
              </Form.Group>
              <Row className="mt-4">
                <Button onClick={handleSubmit} className="mb-1" size="lg">
                  Create Child Account
                </Button>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ChildSignUp;
