import React, { useState,useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

import { Row, Col, Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import SuccessMessage from "../Static/SuccessMessage";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../Static/LoadingContext";

const ResetPassword = () => {

  const { setIsLoading } = useLoading();
  const initialValues = {
    password: "",
    confirmpassword: "",
  }
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const id = localStorage.getItem('Retrieveid');
  const [displaymsg, settDisplayMsg] = useState("");
  const navigate = useNavigate(); // For navigation
  const [isResetSuccessful, setIsResetSuccessful] = useState(false); // Track reset success

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formData));
    settDisplayMsg("")
    setIsSubmit(true);
    
  }
  useEffect(()=>{
    const data={
      token:id,
      new_password:formData.password,
      confirm_password: formData.confirmpassword
    }
    if(isSubmit && Object.keys(formErrors).length === 0){
      setIsLoading(true);
      axios
    .post(process.env.REACT_APP_BACKEND_API_URL + "/reset-password/", data)
    .then((response) => {
      // console.log(response);
      settDisplayMsg(response.data.message);
      setFormData(initialValues);
      setIsResetSuccessful(true); // Set reset success to true
     
    })
    .catch((error) => {
      // console.log(error);
    })
    .finally(() => {
      setIsSubmit(false);
      setIsLoading(false);
    });
    }
    else{
      setIsSubmit(false)
    }
  },[isSubmit,formErrors,formData])

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 30) {
      errors.password = "Password cannot exceed more than 30 characters";
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = "Date is required";
    } else if (values.password != values.confirmpassword) {
      errors.confirmpassword = "Password is not matching";
    }
    return errors;
  };

  const handleSignInRedirect = () => {
    navigate("/"); // Navigate to the sign-in page
  };
  return (
    <>
      <Container
        fluid
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Row>
          <Col md="4"></Col>
          <Col md="6" className="mt-4"></Col>
          <Card
            className="mt-4"
            style={{ maxWidth: "600px", margin: "0 auto" }}
          >
            <Card.Body>
              {displaymsg != "" && <SuccessMessage  message={displaymsg}/>}
              <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5">
                Reset Password
              </h3>

              <Row>
                <Col>
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Enter new password</Form.Label>
                    <Form.Control size="md" type="password" name="password" value={formData.password} onChange={handleChange}
                      isInvalid={formErrors.password} 
                      disabled={isResetSuccessful} // Disable if reset successful

                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control size="md" type="password" name="confirmpassword" value={formData.confirmpassword}
                     onChange={handleChange}
                     isInvalid={formErrors.confirmpassword}
                     disabled={isResetSuccessful} // Disable if reset successful
                     />
                     <Form.Control.Feedback type="invalid">
                       {formErrors.confirmpassword}
                     </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Row>
            {isResetSuccessful ? (
                  <Button onClick={handleSignInRedirect} className="mb-3 mt-2" size="lg">
                    Proceed to Sign In
                  </Button>
                ) : (
                  <Button onClick={handleSubmit} className="mb-3 mt-2" size="lg">
                    Reset Password
                  </Button>
                )}
            </Row>
          </Card>

          <Col md="2"> </Col>
        </Row>
      </Container>


    </>
  );
};

export default ResetPassword;
