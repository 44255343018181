import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const PrivateRoutes = ({allowedRoles}) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_API_URL + "/verify-logged-in-user-token/?user-type=" + localStorage.getItem("userType"), {
          headers: {
            Authorization: `Token ${localStorage.getItem("authToken")}`
          }
        });
        if (response.status === 200 && response.data.message !== "Token is invalid.") {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          localStorage.clear();
          navigate("/");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          localStorage.clear();
          navigate("/");
        }
      }
    };
    fetchAuthToken();
  }, [navigate]);

  if (isAuthenticated === null) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  return isAuthenticated ? (allowedRoles.includes(localStorage.getItem("userType")) ? <Outlet /> : <Navigate to="/noaccess" />) : <Navigate to="/" /> ;
};

export default PrivateRoutes;
