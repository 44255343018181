// import React, { useEffect, useRef } from 'react';
// import MathJax from 'react-mathjax';

// const MathJaxComponent = ({ children }) => {

//   const elementRef = useRef(null);

//   useEffect(() => {
//     MathJax.startup.promise.then(() => {
//       MathJax.typesetPromise(elementRef.current);
//     });
//   }, [children]);
  

//   return (
//     <div ref={elementRef}>
//       <span dangerouslySetInnerHTML={{ __html: children }} />
//     </div>
//   );
// };

// export default MathJaxComponent;


import React, { useEffect, useRef } from 'react';

const MathJaxComponent = ({ children }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const waitForMathJax = () => {
      return new Promise((resolve) => {
        const checkIfLoaded = () => {
          if (window.MathJax && window.MathJax.typeset) {
            resolve();
          } else {
            setTimeout(checkIfLoaded, 100);
          }
        };
        checkIfLoaded();
      });
    };

    waitForMathJax().then(() => {
      if (window.MathJax) {
        window.MathJax.typeset([elementRef.current]);
      }
    });
  }, [children]);

  const content = typeof children === 'string' ? children.trim().replace(/\n/g, ' ') : children;

  return (
    <div ref={elementRef}>
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default MathJaxComponent;
