import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card, Modal, Alert } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaEdit, FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, Toast } from 'react-bootstrap';

export default function UserProfile() {
    const [userProfile, setUserProfile] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        postcode: '',
    });
    const [isParent, setIsParent] = useState(false);
    const [isChild, setIsChild] = useState(false);
    const [children, setChildren] = useState([]);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastVariant, setToastVariant] = useState("success");

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND_API_URL + '/profile/', {
                    headers: { Authorization: `Token ${localStorage.getItem('authToken')}` }
                });
                const data = response.data;
                setUserProfile(data);
                setIsParent(data.is_parent || false);
                setIsChild(data.is_child || false);
                if (data.children) {
                    setChildren(data.children);
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };
        fetchUserProfile();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserProfile({ ...userProfile, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(process.env.REACT_APP_BACKEND_API_URL + '/profile/', userProfile, {
                headers: { Authorization: `Token ${localStorage.getItem('authToken')}` }
            });

            setShowPasswordModal(false);
            setToastMessage("Profile details updated successfully!");
            setToastVariant("success");
            setShowToast(true);
            
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                console.error("Error updating profile:", error);
            }
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrors({ password: 'Passwords do not match' });
            return;
        }

        try {
            const response = await axios.put(process.env.REACT_APP_BACKEND_API_URL + '/profile/', { password, confirm_password: confirmPassword }, {
                headers: { Authorization: `Token ${localStorage.getItem('authToken')}` }
            });
            setShowPasswordModal(false);
            setToastMessage("Password updated successfully!");
            setToastVariant("success");
            setShowToast(true);
            
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                console.error("Error updating password:", error);
            }
        }
    };

    return (
        <>
        <Row className="justify-content-center mt-5">
            <Col md={8}>
                <Card className="shadow">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="text-primary"><FaUser /> Profile Details</h4>
                            <Button variant="outline-primary" onClick={() => setShowPasswordModal(true)}>
                                Update Password
                            </Button>
                        </div>
                        <hr />
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label><FaUser /> First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            value={userProfile.first_name}
                                            onChange={handleChange}
                                            disabled={isChild}  // Disabled for child
                                            isInvalid={!!errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formLastName">
                                        <Form.Label><FaUser /> Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="last_name"
                                            value={userProfile.last_name}
                                            onChange={handleChange}
                                            disabled={isChild}  // Disabled for child
                                            isInvalid={!!errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.last_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>


                            

                            {isParent && (
                                
                                <Row className="mt-3">
                                    <Col md={6}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label><FaEnvelope /> Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={userProfile.email}
                                            onChange={handleChange}
                                            disabled={isChild}  // Disabled for child
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group controlId="formPostcode">
                                        <Form.Label><FaMapMarkerAlt /> Post Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="postcode"
                                            value={userProfile.postcode}
                                            onChange={handleChange}
                                            disabled={isChild}  // Disabled for child
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                
                            </Row>)}

                            <Row className="mt-3">
                                <Col md={6}>
                                    <Form.Group controlId="formUsername">
                                        <Form.Label><FaUser /> Username</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={userProfile.username}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {isChild && (
                                <Alert variant="warning" className="mt-3">
                                    You cannot modify your personal details. Please contact your parent for changes.
                                </Alert>
                            )}

                            <Button variant="primary" type="submit" className="mt-4">
                                Update Profile
                            </Button>
                        </Form>

                        {/* {isParent && (
                            <>
                                <h5 className="mt-5">Children Profiles</h5>
                                <ul>
                                    {children.map((child) => (
                                        <li key={child.user__id}>
                                            {child.user__first_name} ({child.user__username})
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )} */}
                    </Card.Body>
                </Card>
            </Col>

            {/* Modal for updating password */}
            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handlePasswordSubmit}>
                        <Form.Group controlId="formPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formConfirmPassword" className="mt-3">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                isInvalid={!!errors.confirm_password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.confirm_password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update Password
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Row>

<ToastContainer position="top-end" className="p-3" style={{"marginTop": "5vh"}}>
<Toast 
  show={showToast} 
  onClose={() => setShowToast(false)} 
  delay={5000} 
  autohide 
  bg={toastVariant}
  color="light"
>

  <Toast.Body>  {toastMessage}</Toast.Body>
</Toast>
</ToastContainer>
</>
    );
}
