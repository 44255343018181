import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";


const Metadata = (props) => {
  return (
    <>
      <Row>
        <hr className="mt-3"></hr>
        <Col>
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => props.onInputChange(e)}
            id="dataSource"
            name="dataSource"
            className="mt-2"
          >
            <option value="">Select Data Source</option>
            <option value="Seven Oaks">Seven Oaks</option>
            <option value="MGS">MGS</option>
            <option value="LEAP">LEAP</option>
            <option value="AE">AE</option>
            <option value="SDE">SDE</option>
            <option value="Chuckra">Chuckra</option>
            <option value="CGP">CGP</option>
            <option value="ourOwnManual">Original Manual</option>
            <option value="ourOwnAutogen">Original Auto Generated</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => props.onInputChange(e)}
            id="sourceYear"
            name="sourceYear"
            className="mt-2"
          >
            <option value="">Select Source Year</option>
            <option value="NA">NA</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => props.onInputChange(e)}
            id="difficultyLevel"
            name="difficultyLevel"
            className="mt-4"
          >
            <option value="">Select Difficulty Level</option>
            <option value="Level 1">Level 1</option>
            <option value="Level 2">Level 2</option>
            <option value="Level 3">Level 3</option>
            <option value="Level 4">Level 4</option>
            <option value="Level 5">Level 5</option>
            <option value="Level 6">Level 6</option>
            <option value="Level 7">Level 7</option>
            <option value="Level 8">Level 8</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => props.onInputChange(e)}
            id="academicYear"
            name="academicYear"
            className="mt-4"
          >
            <option value="">Select Academic Year</option>
            <option value="Year 3">Year 3</option>
            <option value="Year 4">Year 4</option>
            <option value="Year 5">Year 5</option>
            <option value="Year 6">Year 6</option>
            <option value="Year 7">Year 7</option>
            <option value="Year 8">Year 8</option>
            <option value="Year 9">Year 9</option>
            <option value="Year 10">Year 10</option>
          </Form.Select>
        </Col>
      </Row>
      {props.subject == "maths" && (
        <Row>
          <hr className="mt-3"></hr>
          <label className="mb-2 mt-2">Select applicable topics </label>

          <Col>
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Fractions"
              label="Fractions"
              value="Fractions"
              checked={props.topics.includes("Fractions")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Numbers, Place Value"
              label="Numbers, Place Value"
              value="Numbers, Place Value"
              checked={props.topics.includes("Numbers, Place Value")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Geometric Properties"
              label="Geometric Properties"
              value="Geometric Properties"
              checked={props.topics.includes("Geometric Properties")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Geometric Shapes"
              label="Geometric Shapes"
              value="Geometric Shapes"
              checked={props.topics.includes("Geometric Shapes")}
              onChange={props.handleTopicChange}
            />
          </Col>
          <Col>
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Measurement"
              label="Measurement"
              value="Measurement"
              checked={props.topics.includes("Measurement")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Percentages"
              label="Percentages"
              value="Percentages"
              checked={props.topics.includes("Percentages")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Decimals"
              label="Decimals"
              value="Decimals"
              checked={props.topics.includes("Decimals")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              src="topics"
              type="switch"
              id="topics"
              name="Arithmetic"
              label="Arithemtic"
              value="Arithmetic"
              checked={props.topics.includes("Arithmetic")}
              onChange={props.handleTopicChange}
            />
          </Col>
        </Row>
      )}
      {props.subject == "english" && (
        <Row>
          <Col>
            <Form.Check
              type="switch"
              id="topics"
              name="Antonym"
              label="Antonym"
              value="Antonym"
              checked={props.topics.includes("Antonym")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              type="switch"
              id="topics"
              name="Synonym"
              label="Synonym"
              value="Synonym"
              checked={props.topics.includes("Synonym")}
              onChange={props.handleTopicChange}
            />
            <Form.Check
              type="switch"
              id="topics"
              name="Close"
              label="Close"
              value="Close"
              checked={props.topics.includes("Close")}
              onChange={props.handleTopicChange}
            />
          </Col>
        </Row>
      )}
    </>
  )

}
export default Metadata