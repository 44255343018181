import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Metadata from "./MetaData";
import QSButtons from "./QSButtons";

const MCQuestions = (props) => {
  const [mcMetadata, setMCMetadata] = useState({
    dataSource: "",
    sourceYear: "",
    difficultyLevel: "",
    academicYear: "",
  });
  const [ansOptions, setAnsOptions] = useState({
    ansOption1: "",
    ansOption2: "",
    ansOption3: "",
    ansOption4: "",
    ansOption5: "",
    ansOption6: "",
    correctAnswer: [],
  });
  const [isChecked, setIsChecked] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
  });

  const [topics, setTopics] = useState([]);

  const subject = props.subject;
  const [questionDescription, setQuestionDescription] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerExplanations, setAnswerExplanations] = useState("");
  const [imageFile, setImageFile] = useState(null);



  const handleTopicChange = (event) => {
    const topic = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setTopics([...topics, topic]);
    } else {
      setTopics(topics.filter((t) => t !== topic));
    }
  };


  const handleChange = (event) => {
    const { name, type, value, checked, id, files } = event.target;

    if (type === "checkbox") {
      setIsChecked((prevChecked) => ({
        ...prevChecked,
        [name]: checked,
      }));

      setAnsOptions((prevValues) => {
        const correctAnswer = [...prevValues.correctAnswer];

        if (checked) {
          correctAnswer.push(prevValues["ansOption" + id]);
        } else {
          const index = correctAnswer.indexOf(prevValues["ansOption" + id]);
          if (index !== -1) {
            correctAnswer.splice(index, 1);
          }
        }

        return {
          ...prevValues,
          correctAnswer,
        };
      });
    } else if (id === "topics") {
      const topic = event.target.value;
      const isChecked = event.target.checked;
      if (isChecked) {
        setTopics([...topics, topic]);
      } else {
        setTopics(topics.filter((t) => t !== topic));
      }
    } else if (name === "questionDescription") {
      setQuestionDescription(value);
    } else if (name === "answer") {
      setAnswer(value);
    } else if (name === "answerExplanations") {
      setAnswerExplanations(value);
    } else if (id === "image") {
      const file = event.target.files[0];
      setImageFile(file);
    }
    else if (id === "ansOptions") {
      const fieldName = event.target.name;
      const value = event.target.value;
      setAnsOptions((prevValues) => ({ ...prevValues, [fieldName]: value }));
    }
    else {
      setMCMetadata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const propvalues = {
    questionDescription: questionDescription,
    ansOption1: ansOptions.ansOption1,
    ansOption2: ansOptions.ansOption2,
    ansOption3: ansOptions.ansOption3,
    ansOption4: ansOptions.ansOption4,
    ansOption5: ansOptions.ansOption5,
    ansOption6: ansOptions.ansOption6,
    correctAnswer: ansOptions.correctAnswer,
    answerExplanations: answerExplanations,
    difficultylevel: mcMetadata.difficultyLevel,
    academicYear: mcMetadata.academicYear,
    dataSource: mcMetadata.dataSource,
    sourceYear: mcMetadata.sourceYear,
    topics: topics
  }




  return (
    <>
      <Row>
        <Col>
          <h5 className="mt-4">Add multichoice questions</h5>
          <Form>
            <textarea
              className="mt-2"
              type="text"
              rows={4}
              cols={60}
              name="questionDescription"
              placeholder="Question Description"
              value={questionDescription}
              onChange={handleChange}
            ></textarea>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <label className="mb-1 mt-2">Upload image if available </label>
            <input
              className="form-control"
              id="image"
              type="file"
              name="image"
              value={imageFile}
              onChange={(e) => handleChange(e)}
            />
          </Form>
        </Col>
      </Row>
      <h6 className="mb-2 mt-3 fw-bold">Add answer options</h6>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox1"
            id="1"
            checked={isChecked.checkbox1}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption1"
            value={ansOptions.ansOption1}
            label={ansOptions.ansOption1}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox2"
            id="2"
            checked={isChecked.checkbox2}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption2"
            value={ansOptions.ansOption2}
            label={ansOptions.ansOption2}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox3"
            id="3"
            checked={isChecked.checkbox3}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption3"
            value={ansOptions.ansOption3}
            label={ansOptions.ansOption3}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox4"
            id="4"
            checked={isChecked.checkbox4}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption4"
            value={ansOptions.ansOption4}
            label={ansOptions.ansOption4}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox5"
            id="5"
            checked={isChecked.checkbox5}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption5"
            value={ansOptions.ansOption5}
            label={ansOptions.ansOption5}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            id="6"
            name="checkbox6"
            checked={isChecked.checkbox6}
            onChange={handleChange}
          />
        </Col>
        <Col md="11">
          <Form.Control
            style={{ textAlign: "left" }}
            id="ansOptions"
            type="text"
            name="ansOption6"
            value={ansOptions.ansOption6}
            label={ansOptions.ansOption6}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <textarea
            disabled
            type="textarea"
            rows={5}
            cols={60}
            name="answer"
            placeholder="Correct Anwser"
            value={ansOptions.correctAnswer}
            onChange={handleChange}
            className="mt-4"
          ></textarea>
        </Col>
      </Row>

      <Row>
        <Col>
          <textarea
            type="textarea"
            rows={5}
            cols={60}
            name="answerExplanations"
            placeholder="Anwser Explanation"
            value={answerExplanations}
            onChange={handleChange}
            className="mt-4"
          ></textarea>
        </Col>
      </Row>
      <Metadata onInputChange={handleChange} topics={topics} handleTopicChange={handleTopicChange} subject={subject} />
      <QSButtons propvalues={propvalues} subject={subject} />
    </>
  );
};

export default MCQuestions;
