import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Badge, Form, Button } from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import MathJaxComponent from '../Static/MathJaxComponent'; // Your MathJax component for rendering math expressions
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const FetchImageWithHeaders = ({ imageName }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/fetch-image/${imageName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${localStorage.getItem("authToken")}`, // Add your required headers here
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
        } else {
          console.error('Failed to fetch image:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [imageName]);

  if (!imageSrc) {
    return null; // or a loading spinner
  }

  return (
    <Row>
      <Col md={2}></Col>
      <Col md={6}>
        <Zoom>
          <div style={{ maxHeight: '40vh' }}>
            <img src={imageSrc} alt={imageName} />
          </div>
        </Zoom>
      </Col>
      <Col md={4}></Col>
    </Row>
  );
};

export default function AdminQuestionBankView() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const location = useLocation();
  // const { questionId } = location.state || {};  // Retrieve questionId from state
  // var { subject } = location.state || {};  // Retrieve subject from state
  // subject = subject ? subject.toLowerCase():subject;
  // var { questionType } = location.state || {};  // Retrieve questionType from state
  // questionType = questionType ? questionType.toLowerCase() : questionType;

  // Extract query parameters
  var queryParams = new URLSearchParams(location.search);
  var subject = queryParams.get('subject');
  subject = subject ? subject.toLowerCase():subject;
  var questionType = queryParams.get('questionType');
  var questionId = queryParams.get('questionId');
  questionType = questionType ? questionType.toUpperCase() : questionType;
  console.log(subject, questionType, questionId);

  if (questionType == 'MCQ' || questionType == 'mcq'){
    questionType = 'mc';
  }
  else if (questionType == 'FIB'){
    questionType = 'fib';
  }
  else if (questionType == 'MP'){
    questionType = 'mp';
  }
console.log(questionType);
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const config = {
          headers: { Authorization: `Token ${token}` }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/get-question-data/${subject}/${questionType}/${questionId}/`, config);

        setQuestions(response.data);
        setCurrentQuestion(response.data); // Start with the first question
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestion(questions[currentQuestionIndex + 1]);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrentQuestion(questions[currentQuestionIndex - 1]);
    }
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#E8E8E8" }}>
      <Row>
        <Col md={1}></Col>
        <Col md={7}>
          <Card className="mt-2">
            <Card.Header as="h5" style={{ backgroundColor: "#3366FF", color: "#FFFFFF" }}>Math Practice</Card.Header>
            <Card.Body style={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}>
              <Row>
                {currentQuestion && (
                  <div style={{ position: 'relative' }}>
                    <Badge style={{ position: 'absolute', top: 5, right: 10 }} bg="info">
                      {currentQuestion.score || 1} {currentQuestion.score < 2 ? 'mark' : 'marks'}
                    </Badge>
                    <Card.Title>Question No. {currentQuestionIndex + 1}</Card.Title>
                  </div>
                )}
              </Row>

              <Row>
                <Form.Group>
                  {currentQuestion && (
                    <Card.Text>
                      <div style={{ width: '100%' }}>
                        {/* Display question description */}
                        <MathJaxComponent>{currentQuestion.questionDescription}</MathJaxComponent>

                        {/* Display question image if available */}
                        {currentQuestion.image && (
                          <Row className='m-2'>
                            <FetchImageWithHeaders imageName={currentQuestion.image} />
                          </Row>
                        )}

                        {/* Handle FIB (Fill in the Blank) question type */}
                        {currentQuestion.questionType === "FIB" && (
                          <Row className="align-items-center">
                            <Col>
                              <span>{currentQuestion.answerPrefix}</span>
                              <input
                                className="mt-4"
                                style={{
                                  width: "70%",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderBottom: "1px solid black",
                                  outline: "none"
                                }}
                                type="text"
                                placeholder="Enter your answer here"
                                disabled
                              />
                              <span>{currentQuestion.answerSuffix}</span>
                            </Col>
                          </Row>
                        )}

                        {/* Handle MC (Multiple Choice) question type */}
                        {currentQuestion.questionType === "MCQ" && (
                          <Row style={{ marginLeft: '20px' }}>
                          <Col style={{ height: "20vh", overflowY: "auto" }}>
                            <Row>
                              {currentQuestion.ansOption1 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption1}
                                  id={`option1-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                            <Row>
                              {currentQuestion.ansOption2 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption2}
                                  id={`option2-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                            <Row>
                              {currentQuestion.ansOption3 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption3}
                                  id={`option3-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              {currentQuestion.ansOption4 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption4}
                                  id={`option4-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                            <Row>
                              {currentQuestion.ansOption5 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption5}
                                  id={`option5-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                            <Row>
                              {currentQuestion.ansOption6 && (
                                <Form.Check
                                  type="radio"
                                  label={currentQuestion.ansOption6}
                                  id={`option6-${currentQuestion.id}`}
                                  name={currentQuestion.id}
                                  disabled
                                />
                              )}
                            </Row>
                          </Col>
                        </Row>
                        )}

                        {/* Handle MP (Multi-Part) question type */}
                        {currentQuestion.questionType === "MP" && (
                          <>
                            {currentQuestion.fib_subquestions && currentQuestion.fib_subquestions.map((subQuestion, index) => (
                              <div key={`${index}`} style={{ border: '1px solid #007bff', padding: '10px', borderRadius: '5px', marginTop: '10px', position: 'relative' }}>
                                <strong>Sub Question {index + 1}: </strong>
                                <Badge style={{ position: 'absolute', top: 10, right: 10 }} bg="info">{subQuestion.score} {subQuestion.score < 2 ? 'mark' : 'marks'}</Badge>
                                <MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent>
                                <Row className="align-items-center">
                                  <Col>
                                    <span>{subQuestion.answerPrefix}</span>
                                    <input
                                      className="mt-4"
                                      style={{
                                        width: "70%",
                                        borderTop: "none",
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderBottom: "1px solid black",
                                        outline: "none"
                                      }}
                                      type="text"
                                      placeholder="Enter your answer here"
                                      disabled
                                    />
                                    <span>{subQuestion.answerSuffix}</span>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </Card.Text>
                  )}
                </Form.Group>
              </Row>
            </Card.Body>

            {/* Navigation buttons */}
            <Card.Footer style={{ position: "fixed", bottom: "0", left: "0", right: "0", width: "calc(100% - 30px)", margin: "auto auto" }}>
              <Row>
                <Col md={2}>
                  <Button variant='primary' onClick={handlePrev}>{"<< "}Prev</Button>
                </Col>
                <Col md={2}>
                  <Button variant='primary' onClick={handleNext}>Next{" >>"}</Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>

        {/* Metadata Section */}
        <Col md={4}>
          <Card className="mt-2">
            <Card.Header as="h5" style={{ backgroundColor: "#CCFFFF", color: "Black" }}>
              Metadata
            </Card.Header>
            <Card.Body>
              {currentQuestion && (
                <>
                  <p><strong>Base Question ID: </strong> {currentQuestion.baseQuestionID}</p>
                  <p><strong>Difficulty Level: </strong> {currentQuestion.difficultyLevel}</p>
                  <p><strong>Academic Year: </strong> {currentQuestion.academicYear}</p>
                  <p><strong>Source: </strong> {currentQuestion.dataSource}</p>
                  <p><strong>Source Year:</strong> ({currentQuestion.sourceYear})</p>

                  
                    <p><strong>Correct Answer: </strong> <MathJaxComponent>{currentQuestion.correctAnswer}</MathJaxComponent></p>
                  

                  
                    <p><strong>Explanation: </strong> <MathJaxComponent>{currentQuestion.answerExplanation}</MathJaxComponent></p>

                    <p><strong>Primary Topics: </strong> {currentQuestion.primary_topic} </p>
                    <p><strong>Sub Topics: </strong> {currentQuestion.sub_topic} </p>
                  
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
