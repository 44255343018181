import React, { useState, useEffect } from 'react';
import BootstrapeTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {
  Row,
  Col,
  Button,
  Form
} from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '90%', // Set a max width to ensure it doesn't exceed the viewport width
  maxHeight: '90%', // Set a max height to ensure it doesn't exceed the viewport height
  width: 'auto', // Auto width to fit content
  height: 'auto', // Auto height to fit content
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto', // Add overflow auto to handle scroll for large content
};

export default function QBDashboard() {

  const [MQInput, setMQInput] = useState({
    subject: "",
    choice: "",
  });
  const [QBData, setQBData] = useState([]);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [academicYearData, setAcademicYearData] = useState([]);
  const [stackedBarGraph, setStackedBarGraph] = useState([]);
  const [stackedBarGraphBaseQuestionID, setStackedBarGraphBaseQuestionID] = useState([]);
  const [updateGraph, setUpdateGraph] = useState(false);
  const token = localStorage.getItem("authToken")
  const config = {
    headers: { Authorization: `Token ${token}` }
  };

  useEffect(() => {
    if (MQInput.subject === "Maths") {
      axios.get((process.env.REACT_APP_BACKEND_API_URL)+ "/get-maths-data/", config)
        .then((response) => {
          // console.log(response.data)
          const groupedData = response.data.reduce((acc, curr) => {
            var topics = curr.primary_topic;
            
              // Ensure topics is an array
              if (!Array.isArray(topics)) {
                topics = [topics];
              }
            
            topics.forEach((topic) => {
              if (!acc[topic]) {
                acc[topic] = [];
              }
              acc[topic].push(curr);
            });
            return acc;
          }, {});

          const totalQuestions = Object.values(groupedData).map((questions) => questions.length);
          const Data = Object.keys(groupedData).map((topic, index) => ({
            topic,
            totalQuestions: totalQuestions[index]
          }));
          const DLgroupedData = response.data.reduce((acc, curr) => {
            const difficultyLevel = curr.difficultyLevel;
            if (!acc[difficultyLevel]) {
              acc[difficultyLevel] = [];
            }
            acc[difficultyLevel].push(curr);
            return acc;
          }, {});
          const chartData = Object.keys(DLgroupedData).map((difficultyLevel) => ({
            difficultyLevel,
            totalQuestions: DLgroupedData[difficultyLevel].length,
          }));
          const ACgroupedData = response.data.reduce((acc, curr) => {
            const academicYear = curr.academicYear;
            if (!acc[academicYear]) {
              acc[academicYear] = [];
            }
            acc[academicYear].push(curr);
            return acc;
          }, {});
          const academicYearData = Object.keys(ACgroupedData).map((academicYear) => ({
            academicYear,
            totalQuestions: ACgroupedData[academicYear].length,
          }));
          const groupedStackedData = response.data.reduce((acc, curr) => {
            var topics = curr.primary_topic;
            const difficultyLevel = curr.difficultyLevel;
            
              // Ensure topics is an array
              if (!Array.isArray(topics)) {
                topics = [topics];
              }
            
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][difficultyLevel]) {
                acc[topic][difficultyLevel] = [];
              }
              acc[topic][difficultyLevel].push(curr);
            });
            return acc;
          }, {});

          const stackedBarGraph = Object.entries(groupedStackedData).map(
            ([topic, difficultyLevels]) => {
              const totalQuestions = Object.values(difficultyLevels).reduce(
                (acc, curr) => acc + curr.length,
                0
              );
              const difficulties = Object.entries(difficultyLevels).map(
                ([difficultyLevel, questions]) => ({
                  difficultyLevel,
                  totalQuestions: questions.length,
                })
              );
              return {
                topic,
                totalQuestions,
                difficulties,
              };
            }
          );


          const stackedBarGraphBaseQuestionIDData = response.data.reduce((acc, curr) => {
            var topics = curr.primary_topic;
            const baseQuestionID = curr.baseQuestionID;
            const questionType = curr.questionType;
            
              // Ensure topics is an array
              if (!Array.isArray(topics)) {
                topics = [topics];
              }
            
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][baseQuestionID]) {
                acc[topic][baseQuestionID] = {
                  questions: [],
                  FIB: 0,
                  MCQ: 0,
                  MP: 0
                };
              }
              acc[topic][baseQuestionID].questions.push(curr);
              acc[topic][baseQuestionID][questionType]++;
            });
            return acc;
          }, {});
          
          const stackedBarGraphBaseQuestionID = Object.entries(stackedBarGraphBaseQuestionIDData).map(
            ([topic, baseQuestionIDs]) => {
              const totalQuestions = Object.values(baseQuestionIDs).reduce(
                (acc, { questions }) => acc + questions.length,
                0
              );
              const basequestionids = Object.entries(baseQuestionIDs).map(
                ([baseQuestionID, { questions, FIB, MCQ, MP }]) => ({
                  baseQuestionID,
                  totalQuestions: questions.length,
                  FIB,
                  MCQ,
                  MP
                })
              );
              console.log(topic, totalQuestions, basequestionids);
              return {
                topic,
                totalQuestions,
                basequestionids,
              };
            }
          );
          // Sort the array based on the 'topic' property
          stackedBarGraphBaseQuestionID.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });
          
          // Sort the array based on the 'topic' property
          stackedBarGraph.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });

          // Sort the array based on the 'topic' property
          Data.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });
          // Data.sort((a, b) => a.topic.localeCompare(b.topic));
          // stackedBarGraph.sort((a, b) => a.topic.localeCompare(b.topic));
          setStackedBarGraph(stackedBarGraph)
          setStackedBarGraphBaseQuestionID(stackedBarGraphBaseQuestionID)
          setData(Data);
          setChartData(chartData);
          setQBData(response.data);
          setAcademicYearData(academicYearData);
          
          setUpdateGraph(true);

        });
    }
    else if (MQInput.subject === "English") {
      axios.get((process.env.REACT_APP_BACKEND_API_URL)+ "/get-english-data/", config)
        .then((response) => {
          console.log(response.data)
          const groupedData = response.data.reduce((acc, curr) => {
            var topics = '';
            if (typeof curr.primary_topic == typeof undefined ){
            topics = curr.topic;
            }
            else {
              topics = curr.primary_topic;
            }
            topics.forEach((topic) => {
              if (!acc[topic]) {
                acc[topic] = [];
              }
              acc[topic].push(curr);
            });
            return acc;
          }, {});

          const totalQuestions = Object.values(groupedData).map((questions) => questions.length);
          const Data = Object.keys(groupedData).map((topic, index) => ({
            topic,
            totalQuestions: totalQuestions[index]
          }));
          const DLgroupedData = response.data.reduce((acc, curr) => {
            const difficultyLevel = curr.difficultyLevel;
            if (!acc[difficultyLevel]) {
              acc[difficultyLevel] = [];
            }
            acc[difficultyLevel].push(curr);
            return acc;
          }, {});
          const chartData = Object.keys(DLgroupedData).map((difficultyLevel) => ({
            difficultyLevel,
            totalQuestions: DLgroupedData[difficultyLevel].length,
          }));
          const ACgroupedData = response.data.reduce((acc, curr) => {
            const academicYear = curr.academicYear;
            if (!acc[academicYear]) {
              acc[academicYear] = [];
            }
            acc[academicYear].push(curr);
            return acc;
          }, {});
          const academicYearData = Object.keys(ACgroupedData).map((academicYear) => ({
            academicYear,
            totalQuestions: ACgroupedData[academicYear].length,
          }));
          const groupedStackedData = response.data.reduce((acc, curr) => {
            var topics = '';
            if (typeof curr.primary_topic == typeof undefined ){
            topics = curr.topic;
            }
            else {
              topics = curr.primary_topic;
            }
            const difficultyLevel = curr.difficultyLevel;
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][difficultyLevel]) {
                acc[topic][difficultyLevel] = [];
              }
              acc[topic][difficultyLevel].push(curr);
            });
            return acc;
          }, {});

          const stackedBarGraph = Object.entries(groupedStackedData).map(
            ([topic, difficultyLevels]) => {
              const totalQuestions = Object.values(difficultyLevels).reduce(
                (acc, curr) => acc + curr.length,
                0
              );
              const difficulties = Object.entries(difficultyLevels).map(
                ([difficultyLevel, questions]) => ({
                  difficultyLevel,
                  totalQuestions: questions.length,
                })
              );
              return {
                topic,
                totalQuestions,
                difficulties,
              };
            }
          );


          const stackedBarGraphBaseQuestionIDData = response.data.reduce((acc, curr) => {
            var topics = '';
            if (typeof curr.primary_topic == typeof undefined ){
            topics = curr.topic;
            }
            else {
              topics = curr.primary_topic;
            }
            const baseQuestionID = curr.baseQuestionID;
            const questionType = curr.questionType;
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][baseQuestionID]) {
                acc[topic][baseQuestionID] = {
                  questions: [],
                  FIB: 0,
                  MCQ: 0,
                  MP: 0
                };
              }
              acc[topic][baseQuestionID].questions.push(curr);
              acc[topic][baseQuestionID][questionType]++;
            });
            return acc;
          }, {});
          
          const stackedBarGraphBaseQuestionID = Object.entries(stackedBarGraphBaseQuestionIDData).map(
            ([topic, baseQuestionIDs]) => {
              const totalQuestions = Object.values(baseQuestionIDs).reduce(
                (acc, { questions }) => acc + questions.length,
                0
              );
              const basequestionids = Object.entries(baseQuestionIDs).map(
                ([baseQuestionID, { questions, FIB, MCQ, MP }]) => ({
                  baseQuestionID,
                  totalQuestions: questions.length,
                  FIB,
                  MCQ,
                  MP
                })
              );
              console.log(topic, totalQuestions, basequestionids);
              return {
                topic,
                totalQuestions,
                basequestionids,
              };
            }
          );
          // Sort the array based on the 'topic' property
          stackedBarGraphBaseQuestionID.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });

          // Sort the array based on the 'topic' property
          stackedBarGraph.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });

          // Sort the array based on the 'topic' property
          Data.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });
          // Data.sort((a, b) => a.topic.localeCompare(b.topic));
          // stackedBarGraph.sort((a, b) => a.topic.localeCompare(b.topic));
          setStackedBarGraph(stackedBarGraph)
          setStackedBarGraphBaseQuestionID(stackedBarGraphBaseQuestionID)
          setData(Data);
          setChartData(chartData);
          setQBData(response.data);
          setAcademicYearData(academicYearData);
          
          setUpdateGraph(true);

        });
    }
    else if (MQInput.subject === "VR") {
      axios.get((process.env.REACT_APP_BACKEND_API_URL)+ "/get-vr-data/", config)
        .then((response) => {
          // console.log(response.data)
          const groupedData = response.data.reduce((acc, curr) => {
            var topics = '';
            var topics = curr.primary_topic;
            console.log(topics);
            console.log(Array.isArray(topics));
              // Ensure topics is an array
            if (!Array.isArray(topics)) {
              topics = [topics];
            }
          console.log("Inside the if and isArray is", Array.isArray(topics))
          topics.forEach((topic) => {
            if (!acc[topic]) {
              acc[topic] = [];
            }
            acc[topic].push(curr);
          });
            return acc;
          }, {});

          const totalQuestions = Object.values(groupedData).map((questions) => questions.length);
          const Data = Object.keys(groupedData).map((topic, index) => ({
            topic,
            totalQuestions: totalQuestions[index]
          }));
          const DLgroupedData = response.data.reduce((acc, curr) => {
            const difficultyLevel = curr.difficultyLevel;
            if (!acc[difficultyLevel]) {
              acc[difficultyLevel] = [];
            }
            acc[difficultyLevel].push(curr);
            return acc;
          }, {});
          const chartData = Object.keys(DLgroupedData).map((difficultyLevel) => ({
            difficultyLevel,
            totalQuestions: DLgroupedData[difficultyLevel].length,
          }));
          const ACgroupedData = response.data.reduce((acc, curr) => {
            const academicYear = curr.academicYear;
            if (!acc[academicYear]) {
              acc[academicYear] = [];
            }
            acc[academicYear].push(curr);
            return acc;
          }, {});
          const academicYearData = Object.keys(ACgroupedData).map((academicYear) => ({
            academicYear,
            totalQuestions: ACgroupedData[academicYear].length,
          }));
          const groupedStackedData = response.data.reduce((acc, curr) => {
            var topics = '';
            if (typeof curr.primary_topic == typeof undefined ){
            topics = curr.topic;
            }
            else {
              topics = curr.primary_topic;
            }
            const difficultyLevel = curr.difficultyLevel;
            // Ensure topics is an array
          if (!Array.isArray(topics)) {
            topics = [topics];
          }
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][difficultyLevel]) {
                acc[topic][difficultyLevel] = [];
              }
              acc[topic][difficultyLevel].push(curr);
            });
            return acc;
          }, {});

          const stackedBarGraph = Object.entries(groupedStackedData).map(
            ([topic, difficultyLevels]) => {
              const totalQuestions = Object.values(difficultyLevels).reduce(
                (acc, curr) => acc + curr.length,
                0
              );
              const difficulties = Object.entries(difficultyLevels).map(
                ([difficultyLevel, questions]) => ({
                  difficultyLevel,
                  totalQuestions: questions.length,
                })
              );
              return {
                topic,
                totalQuestions,
                difficulties,
              };
            }
          );


          const stackedBarGraphBaseQuestionIDData = response.data.reduce((acc, curr) => {
            var topics = '';
            if (typeof curr.primary_topic == typeof undefined ){
            topics = curr.topic;
            }
            else {
              topics = curr.primary_topic;
            }
            // Ensure topics is an array
          if (!Array.isArray(topics)) {
            topics = [topics];
          }
            const baseQuestionID = curr.baseQuestionID;
            const questionType = curr.questionType;
            topics.forEach(topic => {
              if (!acc[topic]) {
                acc[topic] = {};
              }
              if (!acc[topic][baseQuestionID]) {
                acc[topic][baseQuestionID] = {
                  questions: [],
                  FIB: 0,
                  MCQ: 0,
                  MP: 0
                };
              }
              acc[topic][baseQuestionID].questions.push(curr);
              acc[topic][baseQuestionID][questionType]++;
            });
            return acc;
          }, {});
          
          const stackedBarGraphBaseQuestionID = Object.entries(stackedBarGraphBaseQuestionIDData).map(
            ([topic, baseQuestionIDs]) => {
              const totalQuestions = Object.values(baseQuestionIDs).reduce(
                (acc, { questions }) => acc + questions.length,
                0
              );
              const basequestionids = Object.entries(baseQuestionIDs).map(
                ([baseQuestionID, { questions, FIB, MCQ, MP }]) => ({
                  baseQuestionID,
                  totalQuestions: questions.length,
                  FIB,
                  MCQ,
                  MP
                })
              );
              console.log(topic, totalQuestions, basequestionids);
              return {
                topic,
                totalQuestions,
                basequestionids,
              };
            }
          );
          // Sort the array based on the 'topic' property
          stackedBarGraphBaseQuestionID.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });

          // Sort the array based on the 'topic' property
          stackedBarGraph.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });

          // Sort the array based on the 'topic' property
          Data.sort((a, b) => {
            if (a.topic < b.topic) {
              return -1;
            }
            if (a.topic > b.topic) {
              return 1;
            }
            return 0;
          });
          // Data.sort((a, b) => a.topic.localeCompare(b.topic));
          // stackedBarGraph.sort((a, b) => a.topic.localeCompare(b.topic));
          setStackedBarGraph(stackedBarGraph)
          setStackedBarGraphBaseQuestionID(stackedBarGraphBaseQuestionID)
          setData(Data);
          setChartData(chartData);
          setQBData(response.data);
          setAcademicYearData(academicYearData);
          
          setUpdateGraph(true);

        });
    }
  }, [MQInput.subject, , updateGraph]);


  const uniquebaseQuestionIDs = new Set();
  console.log("stackedBarGraphBaseQuestionID", stackedBarGraphBaseQuestionID)
  stackedBarGraphBaseQuestionID.forEach((graph) => {
    graph.basequestionids.forEach((basequestion) => {
      uniquebaseQuestionIDs.add(basequestion.baseQuestionID);
    });
  });
  const uniqueDifficultyLevels = new Set();
  stackedBarGraph.forEach((graph) => {
    graph.difficulties.forEach((difficulty) => {
      uniqueDifficultyLevels.add(difficulty.difficultyLevel);
    });
  });

  function getFillByIndex(index) {
    const colors = ["#8884d8", "#82ca9d", "#ffc658", "#8dd1e1", "#a4de6c", "#d0ed57", "#ffc107", "#ff7f50", "#e41a1c", "#377eb8"];
    return colors[index % colors.length];
  }


  
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setMQInput((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const modifyMoalData = (data) => {
    return new Promise((resolve) => {
      setModalData(data);
        resolve();
      })
  };


  const handleOpen = (data) => {
    modifyMoalData(data).then(() => {
      setOpen(true);
    });
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (data) => {
    console.log("data", data)
    handleOpen(data);
  };
  
  

  return (
    <div>
        <h2>QBDashboard</h2>
      <Row>
        <Form.Group controlId="subject">
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => onInputChange(e)}
            name="subject">
            <option value="">Select Subject</option>
            <option value="Maths">Maths</option>
            <option value="English">English</option>
            <option value="VR">VR</option>
            <option value="NVR">NVR</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="choice">
          <Form.Select
            style={{ fontSize: "14px" }}
            onChange={(e) => onInputChange(e)}
            name="choice">
            <option value="">Filter Questions</option>
            <option value="table1">Questions by topic </option>
            <option value="table2">Questions by difficulty Level </option>
            <option value="table3">Questions by Academic Year </option>
            <option value="table4">Questions by Answers with stacked bar graph</option>
            <option value="table5">Questions by Answers with stacked bar graph [BaseQuestionID]</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className='mt-4 mb-4' style={{ alignItems: "center", justifyContent: "center" }}>

        {(MQInput.choice === "table1" || MQInput.choice === "table2" || MQInput.choice === "table3" || MQInput.choice === "table4" || MQInput.choice === "table5") && (
          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Button onClick={() => setUpdateGraph(false)}>
                <FontAwesomeIcon icon={faSyncAlt}/>
              </Button>
            </Col>
          </Row>
        )}
        {MQInput.choice === "table1" &&
          <BarChart width={1200} height={600} data={data}>
            <XAxis dataKey="topic" label={{ value: 'Topics', position: 'insideBottom', offset: -4 }} />
            <YAxis label={{ value: 'Total Questions', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Bar dataKey="totalQuestions" fill="#8884d8" barSize={40} />
          </BarChart>
        }
      </Row>
      <Row className='mt-4 mb-4' style={{ alignItems: "center", justifyContent: "center" }}>
        {MQInput.choice === "table2" &&
          <ResponsiveContainer width={1200} height={600}>
            <BarChart data={chartData}>
              <XAxis dataKey="difficultyLevel" />
              <YAxis label={{ value: 'Total Questions', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Bar dataKey="totalQuestions" fill="#8884d8" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        }
      </Row>
      <Row className='mt-4 mb-4' style={{ alignItems: "center", justifyContent: "center" }}>
        {MQInput.choice === "table3" &&
          <ResponsiveContainer width={1200} height={600}>
            <BarChart data={academicYearData}>
              <XAxis dataKey="academicYear" />
              <YAxis label={{ value: 'Total Questions', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Bar dataKey="totalQuestions" fill="#8884d8" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        }
      </Row>
      <Row>
        {MQInput.choice === "table4" &&
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={stackedBarGraph}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="topic" />
              <YAxis dataKey="totalQuestions" />
              <Tooltip />
              <Legend />
              {(Array.from(uniqueDifficultyLevels)).sort((a, b) => a -b).map((difficultyLevel, index) => (
                <Bar
                  key={difficultyLevel}
                  dataKey={(d) =>
                    d.difficulties.find((d) => d.difficultyLevel === difficultyLevel)
                      ?.totalQuestions || 0
                  }
                  stackId="a"
                  fill={getFillByIndex(index)}
                  name={difficultyLevel}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>

        }
      </Row>
      <Row>
        {MQInput.choice === "table5" &&
          <div>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart data={stackedBarGraphBaseQuestionID}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="topic" />
              <YAxis dataKey="totalQuestions" />
              {/* <Tooltip /> */}
              {/* <Legend /> */}
              {console.log((Array.from(uniquebaseQuestionIDs)).sort((a, b) => a -b))}
              {((Array.from(uniquebaseQuestionIDs)).sort((a, b) => a -b)).map((baseQuestionID, index) => (
                <Bar
                  key={baseQuestionID}
                  dataKey={(d) =>
                    d.basequestionids.find((d) => d.baseQuestionID === baseQuestionID)?.totalQuestions || 0
                  }
                  stackId="a"
                  fill={getFillByIndex(index)}
                  name={baseQuestionID === '' ? 'NILL' : baseQuestionID}
                  onClick={(data) =>{
                    const sortedPayload = {
                      ...data,
                      basequestionids: data.basequestionids.sort((a, b) => a.baseQuestionID.localeCompare(b.baseQuestionID))
                    };
                    handleClick(sortedPayload)

                  } 
                  }
                />
              ))}
            </BarChart>
          </ResponsiveContainer>

              <Modal open={open} onClose={handleClose}>
                <Box sx={style} s>
                  <Typography variant="h6" component="h2">
                    {modalData && modalData.payload ? modalData.topic : "Loading..."}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Base Question ID</TableCell>
                          
                          <TableCell align="right">FIB</TableCell>
                          <TableCell align="right">MCQ</TableCell>
                          <TableCell align="right">MP</TableCell>
                          {modalData && modalData.payload?
                          (<TableCell align="right">Total | {modalData.payload.basequestionids.reduce((sum, row) => sum + row.totalQuestions, 0) || 0} </TableCell>)
                          :
                          (<TableCell align="right">Total</TableCell>)
                          }

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log("modalData.payload", modalData)}
                        {modalData && modalData.payload? (
                          modalData.payload.basequestionids.map((row) => (
                            <TableRow key={row.baseQuestionID}>
                              <TableCell component="th" scope="row">
                                {row.baseQuestionID === '' ? 'NILL' : row.baseQuestionID}
                              </TableCell>
                              <TableCell align="right">{row.FIB}</TableCell>
                              <TableCell align="right">{row.MCQ}</TableCell>
                              <TableCell align="right">{row.MP}</TableCell>
                              <TableCell align="right">{row.totalQuestions}</TableCell>
                              
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              No Data Available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Modal>
            
        </div>

        }
      </Row>
    </div>
  )
}
