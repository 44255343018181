import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { Button, Table, Row, Col, Card, Alert, Collapse } from 'react-bootstrap';
import Cookies from "js-cookie";
import MathJaxComponent from '../Static/MathJaxComponent';
import Zoom from 'react-medium-image-zoom';

const FetchImageWithHeaders = ({ imageName }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/fetch-image/${imageName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${localStorage.getItem("authToken")}`, // Add your required headers here
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
        } else {
          console.error('Failed to fetch image:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();
  }, [imageName]);

  if (!imageSrc) {
    return null; // or a loading spinner
  }

  return (
    <Row>
      <Col md={2}></Col>
      <Col md={6}>
        <Zoom>
          <div style={{ maxHeight: '40vh' }}>
            <img src={imageSrc} alt={imageName} />
          </div>
        </Zoom>
      </Col>
      <Col md={4}></Col>
    </Row>
  );
};

export default function DisplayUserAnswer() {
  useEffect(() => {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
  }, []);

  const location = useLocation();
  const [data, setData] = useState(location?.state);
  const [userValues, setUserValues] = useState("");
  // Add state for tracking expanded rows
const [expandedRows, setExpandedRows] = useState({});

const toggleRow = (index) => {
  setExpandedRows({
    ...expandedRows,
    [index]: !expandedRows[index]
  });
};

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`,
      },
    };
    const id = data.id;
    axios.get(process.env.REACT_APP_BACKEND_API_URL+ `/practice-test-result/${id}/`, config)
      .then((response) => {
        setUserValues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data]);

  const [isSubmit, setIsSubmit] = useState(false);
  const date = new Date(userValues.test_date);
  const formattedDate = date.toLocaleString();
  const Score = userValues.score;
  const handleSubmit = () => {
    setIsSubmit(!isSubmit);
  };

  const disableCopying = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div style={{userSelect: "none" }}
      onContextMenu={disableCopying}
      onCopy={disableCopying}
      onCut={disableCopying}
      onPaste={disableCopying}>
      {/* <Alert key="success" variant="success">
        <Row>
          <Col md="6">
            "Your results got submitted, please click the below button to view your results"
          </Col>
          <Col md="2"></Col>
          <Col md="4">
            <Button onClick={handleSubmit}> View Results</Button>
          </Col>
        </Row>
      </Alert> */}
      <Row className='mt-4'></Row>
      {(userValues) &&
        <Card>
          <div>
            <Card.Body>
              <Card.Title className='mt-2' as="h2">Test Results</Card.Title>
              <Row className="mb-3">
                <Col>
                  <Card.Header style={{ backgroundColor: "#e9ecef", fontWeight: "bold" }}>Date and Time: {formattedDate}</Card.Header>
                </Col>
                <Col>
                  <Card.Header style={{ backgroundColor: "#e9ecef", fontWeight: "bold" }}>
                    Score: {Score} / {userValues.total_score} ({((Score / userValues.total_score) * 100).toFixed(1)}%)
                  </Card.Header>
                </Col>
              </Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Description</th>
                    <th>Your Answers</th>
                    <th>Correct Answer</th>
                    <th>Answer Explanation</th>
                  </tr>
                </thead>
                <tbody>
                  {userValues && userValues.questions && userValues.questions.map((question, index) => {
                    if (question.question_type === 'MP') {
                      return (
                        <>
                          <tr key={`main-${index}`}>
                            <td>{index + 1}</td>
                            <td colSpan="4"><MathJaxComponent>{question.questionDescription}</MathJaxComponent></td>
                          </tr>
                          {question.fib_subquestions.map((subQuestion, subIndex) => {
                            const subUserAnswer = userValues.user_answer[subQuestion.id];
                            const subCorrectAnswer = subQuestion.correctAnswer;
                            const subIsMatch = subUserAnswer === subCorrectAnswer;
                            const subClassName = subIsMatch ? 'table-success' : 'table-danger';
                            return (
                              <>
                              <tr key={`fib-sub-${index}-${subIndex}`} className={subClassName}>
                                <td>{index + 1}.{subIndex + 1}</td>
                                <td><MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent></td>
                                <td>
                                  <p>{subUserAnswer}</p>
                                </td>
                                <td>
                                  <p>{subCorrectAnswer}</p>
                                </td>
                                <td>
                                  {subQuestion.answerExplanation && (<Button onClick={() => toggleRow(`fib-sub-${index}-${subIndex}`)}>Explanation</Button>)}
                                </td>
                              </tr>
                                <tr>
                                  <td colSpan="5" style={{ padding: 0, border: 0 }}>
                                    <Collapse in={expandedRows[`fib-sub-${index}-${subIndex}`]}>
                                      <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                                        

                                      <MathJaxComponent>{subQuestion.answerExplanation}</MathJaxComponent>
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          {question.mc_subquestions.map((subQuestion, subIndex) => {
                            const subUserAnswer = userValues.user_answer[subQuestion.id];
                            const subCorrectAnswer = subQuestion.correctAnswer;
                            const subIsMatch = subUserAnswer === subCorrectAnswer;
                            const subClassName = subIsMatch ? 'table-success' : 'table-danger';
                            return (
                              <>
                              <tr key={`mc-sub-${index}-${subIndex}`} className={subClassName}>
                                <td>{index + 1}.{subIndex + 1 + question.fib_subquestions.length}</td>
                                <td><MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent></td>
                                <td>
                                  <p>{subUserAnswer}</p>
                                </td>
                                <td>
                                  <p>{subCorrectAnswer}</p>
                                </td>
                                <td>
                                  {subQuestion.answerExplanation && (<Button onClick={() => toggleRow(`mc-sub-${index}-${subIndex}`)}>Explanation</Button>)}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="5" style={{ padding: 0, border: 0 }}>
                                  <Collapse in={expandedRows[`mc-sub-${index}-${subIndex}`]}>
                                    <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                                      
                                      <MathJaxComponent>{subQuestion.answerExplanation}</MathJaxComponent>
                                    </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      );
                    }

                    var userAnswer = ''
                    try {
                      userAnswer = userValues.user_answer[question.id][question.id];
                    } catch (error) {
                      userAnswer = userValues.user_answer != null ? userValues.user_answer[index] : "-";
                    }
                    // const userAnswer = userValues.user_answer[question.id][question.id];
                    const correctAnswer = question.correctAnswer;
                    const isMatch = userAnswer === correctAnswer;
                    const className = isMatch ? 'table-success' : 'table-danger';
                    return (
                      <>
                        <tr key={index} className={className}>
                          <td>{index + 1}</td>
                          <td><MathJaxComponent>{question.questionDescription}</MathJaxComponent></td>
                          <td>
                            <p>{userAnswer}</p>
                          </td>
                          <td>
                            <p>{correctAnswer}</p>
                          </td>
                          <td>
                            {question.answerExplanation && (<Button onClick={() => toggleRow(index)}>Explanation</Button>)}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="5" style={{ padding: 0, border: 0 }}>
                            <Collapse in={expandedRows[index]}>
                              <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                              <Row className='m-2'>
                                          {question.image_for_answer_explanation && (
                                            <FetchImageWithHeaders imageName={question.image_for_answer_explanation} />
                                          )}
                                        </Row>
                                <MathJaxComponent>{question.answerExplanation}</MathJaxComponent>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>


              </Table>
            </Card.Body>
          </div>
        </Card>
      }
    </div>
  );
}
