import axios from "axios";

const Instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL + "",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("authToken")}`,
    },
  });

  export default Instance;