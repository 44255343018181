import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import {
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import useSignOut  from "./utils";


const Header = (props) => {
  const navigate = useNavigate();
  const SignOut = useSignOut();
  const [PTInput, setPTInput] = useState({
    ResetChild: "",
    new_password: "",
    confirmpassword: "",
    performceArea: ""
  });
  const [state, setState] = useState({
    token: localStorage.getItem("authToken") || null,
    userType: localStorage.getItem("userType") || null,
    firstName: localStorage.getItem("firstName") || null,
    children: localStorage.getItem("children") ? JSON.parse(localStorage.getItem("children")) : [] || null,
    loading: true // add loading state
  });
  const [showModal, setShowModal] = useState(false);
  const [isReset, setIsReset] = useState(false);
  useEffect(() => {
    const data = {
      email_or_username: PTInput.ResetChild,
      new_password: PTInput.new_password,
      confirm_password: PTInput.confirmpassword
    }
    if (isReset && PTInput.ResetChild != "") {
      axios
        .post((process.env.REACT_APP_BACKEND_API_URL)+ "/forgot-password/", data)
        .then((response) => {
          // console.log(response);
          setIsReset(false);
          setDisplayMessage(response.data.message);
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          setIsReset(false);
        });
    }
    else {
      setIsReset(false);
      setDisplayMessage("Child field cannot be blank");
    }
  }, [PTInput.ResetChild, isReset])

  const firstName = state.firstName;
  const Username = state.children
  const namesArray = Array.isArray(Username) && Username.map((user) => user[1]);
  const usernameArray = Array.isArray(Username) && Username.map((user) => user[2]);
  const token = localStorage.getItem("authToken")
  const config = {
    headers: { Authorization: `Token ${token}` }
  };
  const [displayMessage, setDisplayMessage] = useState("");
  useEffect(() => {
    setState({
      token: localStorage.getItem("authToken") || null,
      userType: localStorage.getItem("userType") || null,
      firstName: localStorage.getItem("firstName") || null,
      children: localStorage.getItem("children") ? JSON.parse(localStorage.getItem("children")) : [],
      loading: false
    });
  }, [localStorage.getItem("authToken"), localStorage.getItem("userType"), localStorage.getItem("firstName"), localStorage.getItem("children")]);



  const onInputChange = (e) => {
    const { name, value } = e.target;
    setPTInput((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const SignOut = () => {
  //   axios
  //     .post(process.env.REACT_APP_BACKEND_API_URL + "/logout/","", config)
  //     .then((response) => {
  //       // console.log(response)
  //       localStorage.clear()
  //       const message = response.data.success;
  //       navigate("/", { state: { message }, replace: true });
  //       window.history.replaceState(null, '');
  //     })
  //     .catch((error) => {
  //     });
  // };
  const resetPassword = (e) => {
    e.preventDefault();
    setIsReset(true);
  }
  const modal = () => {
    setShowModal(true);
    setPTInput((prevFormData) => ({
      ...prevFormData,
      "ResetChild": "",
    }));
  }


  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container fluid className="d-flex justify-content-between align-items-center" >
        <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => navigate("/")}>Practice Papers</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {(state.userType === "parent") &&
              <Nav.Link style={{ cursor: 'pointer' }} onClick={() => navigate("/home")}>Home</Nav.Link>
            }
            {(state.userType === "child" || state.userType === "qbAdmin" || state.userType === "super admin") &&
              <Nav.Link onClick={() => navigate("/ppstartpage")}>Practice Tests</Nav.Link>
            }
            {(state.userType === "qbAdmin" || state.userType === "super admin") &&
            <>
              <NavDropdown style={{ cursor: 'pointer' }} title="Manage Question Bank" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => navigate("/addquestions")}>
                  Add new questions </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/modifyquestions")}>Modify questions</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/DuplicateValues")}>Find duplicates</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/QBDashboard")}>QB Dashboard</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => navigate("/QBDashboard")}>QB Dashboard</Nav.Link>
              </>
            }
            {(state.userType === "qbAdmin" || state.userType === "super admin") &&
              <Nav.Link onClick={() => navigate("/practiceTestsList")}>Practice Test Submissions</Nav.Link>
            }

            {(state.userType === "qbDeveloper" || state.userType === "qbDeveloper") &&
            <>
              <Nav.Link onClick={() => navigate("/mathsquestions")}>View Maths Questions</Nav.Link>
              </>
            }
          </Nav>
        </Navbar.Collapse>

        {(state.token && state.userType == "child") && (
          <Nav className="me-auto">
          <NavDropdown
            style={{ cursor: 'pointer', marginRight: '20px' }}
            title={<span>
              <FontAwesomeIcon icon={faUser} /> {state.firstName}
            </span>}
            id="basic-nav-dropdown"
            align="end"
            menualign="end"
            className="position-static"
          >
            {/* Profile Section Heading */}
            <NavDropdown.Header>Profile</NavDropdown.Header>
            
            {/* View Your Profile Option */}
            <NavDropdown.Item onClick={() => navigate("/profile")}>View Your Profile</NavDropdown.Item>
            
            {/* Divider */}
            <NavDropdown.Divider />
        
            {/* Sign Out Option at Bottom */}
            <NavDropdown.Item onClick={SignOut}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        
        )}

        {(state.token && state.userType != "child") && (
          <Nav className="me-auto">
          <NavDropdown
            style={{ cursor: 'pointer', marginRight: '20px' }}
            title={<span>
              <FontAwesomeIcon icon={faUser} /> {state.firstName}
            </span>}
            id="basic-nav-dropdown"
            align="end"
            menualign="end"
            className="position-static"
          >
            {/* Profile Section Heading */}
            <NavDropdown.Header>Profile</NavDropdown.Header>
            
            {/* View Your Profile Option */}
            <NavDropdown.Item onClick={() => navigate("/profile")}>View Your Profile</NavDropdown.Item>
        
            {/* Change Child's Account Option */}
            {state.children.length !== [].length && (
              <NavDropdown.Item onClick={modal}>
                Change your child's account
              </NavDropdown.Item>
            )}
            
            {/* Divider */}
            <NavDropdown.Divider />
        
            {/* Sign Out Option at Bottom */}
            <NavDropdown.Item onClick={SignOut}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        
        )}

      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Card>
          <Card.Body>
            <Form.Group controlId="ResetChild">
              <Modal.Header closeButton>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Label style={{ marginRight: "10px" }}>
                    Change password for your child
                  </Form.Label>
                  <Form.Select
                    style={{
                      fontSize: "14px",
                      borderRight: "none",
                      borderLeft: "none",
                      borderTop: "none",
                      borderRadius: 0,
                      width: "100px",
                      outline: "none",
                      boxShadow: "none"
                    }}
                    onChange={(e) => onInputChange(e)}
                    name="ResetChild"
                  >
                    <option value=""></option>
                    {namesArray && namesArray.map((name, index) => (
                      <option key={index} value={usernameArray[index]}>{name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Modal.Header>

            </Form.Group>
            <Modal.Body>
              {PTInput.ResetChild != "" &&
                <>
                  <Form.Group className="mb-2 mt-4" controlId="form4">
                    <Form.Label>Enter new password</Form.Label>
                    <Form.Control size="md" type="password" name="new_password" value={PTInput.new_password} onChange={onInputChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="form4">
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control size="md" type="password" name="confirmpassword" value={PTInput.confirmpassword}
                      onChange={onInputChange}
                    />
                  </Form.Group>
                  <Button onClick={resetPassword}>Reset Password</Button>
                </>
              }
            </Modal.Body>
          </Card.Body>
        </Card>
      </Modal>
    </Navbar>
  );
}

export default Header;
