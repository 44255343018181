import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import Cookies from "js-cookie";
import Alert from 'react-bootstrap/Alert';
import ResetPassword from './ResetPassword';
import { useLoading } from "../Static/LoadingContext";

function ResetPasswordPage(props) {
    
    const { success, message } = props;

    if (success === true) {
        return (
            <ResetPassword />
        );
    } else {
        return (
            <>
                {message && (
                    <Alert variant='danger'>{message.email_or_username}</Alert>
                )}
            </>
        );
    }
}

function ResetPasswordRedirect(props) {
    const { setIsLoading } = useLoading();
    const { id } = useParams();
    const [success, setSuccess] = React.useState(undefined);
    const [message, setMessage] = React.useState(undefined);
    localStorage.setItem('Retrieveid', id);

    useEffect(() => {
        setIsLoading(true);
        const csrftoken = Cookies.get("csrftoken");
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/verify-token/${id}/`)
            .then(response => {
                // console.log(response);
                if (response.status === 200) {
                    setSuccess(true);
                    setMessage(response.data); // Assume response.data contains the message object
                } else {
                    setSuccess(false);
                    setMessage(response.data); // Assume response.data contains the message object
                }
            })
            .catch(error => {
                if (error.response == undefined) {
                    setSuccess(false);
                    setMessage("We are currently facing technical issue. Please try again later. (Error code " + error.code + ")");
                }
                else {
                    setSuccess(false);
                    setMessage(error.response.data); // Assume error.response.data contains the message object
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [id]);

    if (success === undefined) {
        return <h1>Loading...</h1>;
    }

    return <ResetPasswordPage success={success} message={message} />;
}

export { ResetPasswordPage, ResetPasswordRedirect };
