import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";


const QSButtons = (props) =>{
  const {questionDescription,
  ansOption1,
  ansOption2,
  ansOption3,
  ansOption4,
  ansOption5,
  ansOption6,
    correctAnswer,
    answerExplanations,
    difficultylevel,
    academicYear,
    dataSource,
    sourceYear,
    topics} = props.propvalues

    const Save = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("questionDescription", questionDescription);
        formData.append("ansOption1", ansOption1);
        formData.append("ansOption2", ansOption2);
        formData.append("ansOption3", ansOption3);
        formData.append("ansOption4", ansOption4);
        formData.append("ansOption5", ansOption5);
        formData.append("ansOption6", ansOption6);
        formData.append("correctAnswer", correctAnswer);
        formData.append("answerExplanation", answerExplanations);
        formData.append("difficultyLevel", difficultylevel);
        formData.append("academicYear", academicYear);
        formData.append("dataSource", dataSource);
        formData.append("sourceYear", sourceYear);
        // const topicsArray = topics.map((t) => t.trim());
        formData.append("topic", topics);
        {
          props.subject === "maths" &&
            axios
              .post(
                process.env.REACT_APP_BACKEND_API_URL + "/MathsMC/create/",
    
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                // console.log(response);
              })
              .catch((error) => {
                // console.log(error);
              });
        }
        {
         props.subject === "english" &&
            axios
              .post(
                process.env.REACT_APP_BACKEND_API_URL + "/EnglishMC/create/",
    
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                // console.log(response);
              })
              .catch((error) => {
                // console.log(error);
              });
        }
      };
      // const SaveNew = (e) => {
      //   e.preventDefault();
      //   {
      //     subject === "maths" &&
      //       axios
      //         .post(process.env.REACT_APP_BACKEND_API_URL + "/fillintheblanks/create/", {
      //           // fibQuestion,
      //           answer,
      //         })
      //         .then((response) => {
      //           // console.log(response);
      //         })
      //         .catch((error) => {
      //           // console.log(error);
      //         });
      //   }
      // };
      // const SaveClose = (e) => {
      //   e.preventDefault();
      //   {
      //     subject === "maths" &&
      //       axios
      //         .post(process.env.REACT_APP_BACKEND_API_URL + "/fillintheblanks/create/", {
      //           // fibQuestion,
      //           answer,
      //         })
      //         .then((response) => {
      //           // console.log(response);
      //         })
      //         .catch((error) => {
      //           // console.log(error);
      //         });
      //   }
      // };

    return(
       <Row className="mt-3">
         <hr className="mt-3"></hr>
        <Col>
          <Button
            className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
            role="button"
            onClick={""}
          >
            Save & New
          </Button>
        </Col>
        <Col>
          <Button
            className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
            role="button"
            onClick={Save}
          >
            Save
          </Button>
        </Col>
        <Col>
          <Button
            className="fw-bold ripple ripple-surface btn btn-primary btn-md w-100 mb-4"
            role="button"
            onClick={""}
          >
            {" "}
            Save & Close
          </Button>
        </Col>
      </Row>
    )

}
export default QSButtons;