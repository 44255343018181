// utils.js
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useSignOut = () => {
  const navigate = useNavigate();

  const SignOut = () => {
    const token = localStorage.getItem("authToken");
    const config = {
      headers: { Authorization: `Token ${token}` }
    };

    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/logout/", "", config)
      .then((response) => {
        localStorage.clear();
        const message = response.data.success;
        navigate("/", { state: { message }, replace: true });
        window.history.replaceState(null, '');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return SignOut;
};

export default useSignOut;
