import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

const MCForm=(props)=>{
    return(
        <>
      <Row>
        <Col>
          <h5 className="mt-4">Add multichoice questions</h5>
          <Form>
            <textarea
              className="mt-2"
              type="text"
              rows={4}
              cols={60}
              id={props.index}
              name="questionDescription"
              placeholder="Question Description"
              value={props.propvalues.questionDescription}
              onChange={props. handleChanges}
            ></textarea>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <label className="mb-1 mt-2">Upload image if available </label>
            <input
              className="form-control"
              id="image"
              type="file"
              name="image"
              value={""}
              onChange={props. handleChanges}
            />
          </Form>
        </Col>
      </Row>
      <h6 className="mb-2 mt-3 fw-bold">Add answer options</h6>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox1"
            id="1"
            checked={props.propvalues.checkbox1}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption1"
            value={props.propvalues.ansOption1}
            label={""}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox2"
            id="2"
            checked={props.propvalues.checkbox2}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption2"
            value={props.propvalues.ansOption2}
            label={""}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox3"
            id="3"
            checked={props.propvalues.checkbox3}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption3"
            value={props.propvalues.ansOption3}
            label={""}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox4"
            id="4"
            checked={props.propvalues.checkbox4}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption4"
            value={props.propvalues.ansOption4}
            label={""}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            name="checkbox5"
            id="5"
            checked={props.propvalues.checkbox5}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            type="text"
            id="ansOptions"
            name="ansOption5"
            value={props.propvalues.ansOption5}
            label={""}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row className="mb-1 mt-2">
        <Col md="1">
          <Form.Check
            type="checkbox"
            id="6"
            name="checkbox6"
            checked={props.propvalues.checkbox6}
            onChange={props.handleChanges}
          />
        </Col>
        <Col md="11">
          <Form.Control
            style={{ textAlign: "left" }}
            id="ansOptions"
            type="text"
            name="ansOption6"
            value={props.propvalues.ansOption6}
            onChange={props.handleChanges}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <textarea
          disabled
            type="textarea"
            rows={5}
            cols={60}
            id={props.index}
            name="answer"
            placeholder="Correct Anwser"
            value={props.propvalues[props.index].correctAnswer}
            onChange={props.handleChanges}
            className="mt-4"
          ></textarea>
        </Col>
      </Row> 
      <Row>
        <Col>
          <textarea
            type="textarea"
            rows={5}
            cols={60}
            id={props.index}
            name="answerExplanation"
            placeholder="Anwser Explanation"
            value={props.propvalues.answerExplanation}
            onChange={props.handleChanges}
            className="mt-4"
          ></textarea>
        </Col>
      </Row> 
     
    </> 
    );
}
export default MCForm;