import React from "react";
import { TailSpin } from "react-loader-spinner";

const withLoader = (Component) => {
  return class WithLoader extends React.Component {
    state = {
      isLoading: false,
    };

    // A function that can be called to set isLoading to true
    // This is useful when you want to trigger the loader manually
    startLoading = () => {
      this.setState({ isLoading: true });
    };

    // A function that can be called to set isLoading to false
    // This is useful when you want to hide the loader manually
    stopLoading = () => {
      this.setState({ isLoading: false });
    };

    render() {
      const { isLoading } = this.state;

      return (
        <>
          {isLoading && (
            <div className="loader-overlay">
              <TailSpin color="#000" height={80} width={80} />
            </div>
          )}
          <Component
            {...this.props}
            isLoading={isLoading}
            startLoading={this.startLoading}
            stopLoading={this.stopLoading}
          />
        </>
      );
    }
  };
};

export default withLoader;
