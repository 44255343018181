import React, { useState } from 'react'
import { useEffect } from 'react'
import SuccessMessage from '../Static/SuccessMessage'
import ChildSignUp from './ChildSignUp';
import { useLocation } from 'react-router';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Instance from '../Static/axiosInstance';
import { FaPlus } from 'react-icons/fa';
import TickCard from "../Static/TickCard"
import { Modal, Collapse } from 'react-bootstrap';
import { Tabs, Tab, Table } from 'react-bootstrap';
import axios from "axios";
import withLoader from '../Static/withLoader';
import { Link } from 'react-router-dom';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Cookies from "js-cookie";
import MathJaxComponent from '../Static/MathJaxComponent';

function Home(props) {
  const [PTInput, setPTInput] = useState({
    subject: "Maths",
    child: "",
    ResetChild: "",
    new_password: "",
    confirmpassword: "",
    performceArea: ""
  });

  const [state, setState] = useState({
    token: localStorage.getItem("authToken") || null,
    userType: localStorage.getItem("userType") || null,
    children: localStorage.getItem("children") ? JSON.parse(localStorage.getItem("children")) : [] || null,
    loading: true
  });
  const [showModal, setShowModal] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  var [barChartDate, setBarChartDate] = useState([]);
  var [barChartTopic, setbarChartTopic] = useState([]);
  var [barChartLevel, setBarChartLevel] = useState([]);
  var [tickCardData, setTickCardData] = useState({});
  // Add state for tracking expanded rows
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows({
      ...expandedRows,
      [index]: !expandedRows[index]
    });
  };

  const formatDateTime = (datetimeString) => {
    // Parse the input datetime string
    const date = new Date(datetimeString);
  
    // Extract the desired components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
  
    // Construct the formatted date string
    return `${hours}:${minutes} ${day}/${month}/${year}`;
  };


  const [scores, setScores] = useState({
    Maths: { average: 0, overall: 0 },
    English: { average: 0, overall: 0 },
    VR: { average: 0, overall: 0 },
    NVR: { average: 0, overall: 0 }
  });

  const Username = state.children
  const namesArray = Array.isArray(Username) && Username.map((user) => user[1]);
  const [isDashboardDataLoaded, setIsDashboardDataLoaded] = useState(false); // State to track if dashboard data is loaded


  const [activeTab, setActiveTab] = useState();
  const location = useLocation();

  const message = location?.state?.message;

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const lastIndex = query.get('state');
  //   if (lastIndex) {
  //     console.log("LastIndex in home page", lastIndex);
  //     setActiveTab(lastIndex);
  //     handleTabSelect(lastIndex);
  //     onInputChange({ target: { name: "subject", value: "Maths" } });
  //     // setActiveTab(lastIndex);
  //     // fetchDashboardData(childId);
  //   }
  //   else{
  //     console.log("LastIndex in home page", lastIndex);
  //     setActiveTab(0);
      
  //   }

  // }, [location.search] )


  const handlePlusClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowChildForm = () => {
    setShowChildForm(true);
  };
  
  const setDashboardDataPromise = (data) => {
    return new Promise((resolve) => {
      setDashboardData(data);
      console.log(dashboardData)
        resolve();
      })
  };

  useEffect(() => {
    if (dashboardData) {
      console.log(dashboardData); // This will log the updated dashboardData
      onInputChange({
        target: {
          name: 'subject',
          value: 'Maths',
        },
      });
    }
  }, [dashboardData]); // This will run when dashboardData changes

  const fetchDashboardData = (childId) => {
    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/exams/last-five/${childId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('authToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log("before promise", data)
        setDashboardData(data);
        setScores({
          Maths: { total_length: data['Maths'].length, average: data['Maths'][(data['Maths'].length - 1).toString()]['average_of_last_five_tests'].toFixed(2), overall: data['Maths'][(data['Maths'].length - 1).toString()]['overall_average_score'].toFixed(2) },
          English: { total_length: data['English'].length, average: data['English'][(data['English'].length - 1).toString()]['average_of_last_five_tests'].toFixed(2), overall: data['English'][(data['English'].length - 1).toString()]['overall_average_score'].toFixed(2) },
          VR: { total_length: data['VR'].length, average: data['VR'][(data['VR'].length - 1).toString()]['average_of_last_five_tests'].toFixed(2), overall: data['VR'][(data['VR'].length - 1).toString()]['overall_average_score'].toFixed(2) },
          NVR: { total_length: data['NVR'].length, average: data['NVR'][(data['NVR'].length - 1).toString()]['average_of_last_five_tests'].toFixed(2), overall: data['NVR'][(data['NVR'].length - 1).toString()]['overall_average_score'].toFixed(2) }
        });
        
      })
      .catch(error => {
        console.error(error);
      });
  };



  const handleTabSelect = (index) => {
    setPTInput((prevFormData) => ({
      ...prevFormData,
      subject: 'Maths',
    }));
    if (index === '+') {
      setShowChildForm(true);
      setActiveTab(index);
    } else {
      setShowChildForm(false);
      const childId = state.children[index][0];
      console.log("Active tab index is", index)
      setActiveTab(index);
      fetchDashboardData(childId);
      onInputChange({ target: { name: "subject", value: "Maths" } });
    }
  };


  useEffect(() => {
    
    
    if (namesArray && namesArray.length === 1)
      setPTInput((prevFormData) => ({
        ...prevFormData,
        "child": Username[0][1],
      }));
  }, [namesArray && namesArray.length === 1])

  
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setPTInput((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    var subjectName = "";
    var graphType = "";

    if(name=="subject"){
      console.log(scores[value])
      subjectName = value;
      graphType = PTInput["performceArea"];
      var total_ActualScores = {
        "total":0,
        "actual":0
      };
      var id=0
      if (dashboardData){
      dashboardData[value].forEach(element => {
        if('id' in element){
          if(element["id"]>id){
            id=element["id"];
            total_ActualScores["lastExamDate"] = formatDateTime(element["test_date"])
          }
          total_ActualScores["total"]+=element["total_score"];
          total_ActualScores["actual"]+=element["score"];
        }
      });
      setTickCardData(total_ActualScores);
      var date_score=[];
    }
    }


        var date_score=[];
        if (dashboardData){
        dashboardData[subjectName].forEach(element => {
          if('id' in element){
            date_score.push({
              "date": element["test_date"].split("T")[0],
              "percentage": parseFloat(((element['score']/element['total_score'])*100).toFixed(2))
            })
          }
        });
        // console.log(date_score)
        setBarChartDate(date_score);
      }
        

      // else if(graphType=='Level'){
      //   var level_score=[];
      //   dashboardData[subjectName].forEach(element => {
      //     if('last_5_percentage_by_difficulty_level' in element){
      //       var actualElementLevelScore = element["last_5_percentage_by_difficulty_level"];
      //       for(var key in actualElementLevelScore){

      //         level_score.push({
      //         "level": "Level " + key,
      //         "percentage": actualElementLevelScore[key]
      //       })
      //       }
      //     }
      //   });
      //   // console.log(level_score)
      //   setBarChartLevel(level_score);
        
      // }
        var actualScoreTopic={};
        var totalScoreTopic={};
        if (dashboardData){
        dashboardData[subjectName].forEach(element => {
          if('id' in element){

            if(element["score_by_topic"]!=null){
              var actualElementTopicScore = JSON.parse(element["score_by_topic"].replace(/'/g, '"'));
              var totalElementTopicScore = JSON.parse(element["total_score_by_topic"].replace(/'/g, '"'));
              for(var key in actualElementTopicScore){
                if(key in actualScoreTopic){
                  actualScoreTopic[key]+=actualElementTopicScore[key];
                }
                else{
                  actualScoreTopic[key]=actualElementTopicScore[key];
                }
              }
            }
            for(var key in totalElementTopicScore){
              if(key in totalScoreTopic){
                totalScoreTopic[key]+=totalElementTopicScore[key];
              }
              else{
                totalScoreTopic[key]=totalElementTopicScore[key];
              }
            }
            // console.log(actualScoreTopic);
            // console.log(actualElementTopicScore);
          }
        });
        var chartDataTopic=[]
        for(var key in actualScoreTopic){
          if (totalScoreTopic[key] != 0){
          chartDataTopic.push(
            {
              "topic":key,
              "actual_marks":actualScoreTopic[key],
              "total_marks":totalScoreTopic[key],
              "percentage": parseFloat(((actualScoreTopic[key]/totalScoreTopic[key])*100).toFixed(2))
            });
          }
        }

        // // console.log(date_score)
        setbarChartTopic(chartDataTopic);
        // // console.log(barChartDate);
      }
  }
  



  const [displayMessage, setDisplayMessage] = useState("");
  
  const [pieData, setPieData] = useState([]);
  const childName = PTInput && PTInput.child;

  const activityData = [
    { child: "Annie", type: "login", date: "2022-03-25T08:00:00.000Z" },
    { child: "Kamal", type: "login", date: "2022-03-24T08:00:00.000Z" },
    { child: "Annie", type: "exam", date: "2022-03-22T08:00:00.000Z" },
    { child: "Kamal", type: "exam", date: "2022-03-20T08:00:00.000Z" },
    { child: "Annie", type: "login", date: "2022-03-18T08:00:00.000Z" },
    { child: "Kamal", type: "login", date: "2022-03-16T08:00:00.000Z" },
    { child: "Annie", type: "exam", date: "2022-03-14T08:00:00.000Z" },
    { child: "Kamal", type: "exam", date: "2022-03-12T08:00:00.000Z" },
  ];



  const success = props.success

 


  const [showChildForm, setShowChildForm] = useState(false);
  const tickValues = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);
  const maxScore = 100;
  // const chartallData = data.map(({ date, score }) => ({ date, score, percent: score / maxScore * 100 }));
  // const chartData = data.slice(0, 5).map(({ date, score }) => ({ date, score, percent: score / maxScore * 100 }));
  useEffect(() => {
    const data = {
      email_or_username: PTInput.ResetChild,
      new_password: PTInput.new_password,
      confirm_password: PTInput.confirmpassword
    }
    if (isReset && PTInput.ResetChild != "") {
      axios
        .post((process.env.REACT_APP_BACKEND_API_URL) + "/forgot-password/", data)
        .then((response) => {
          // console.log(response);
          setIsReset(false);
          setDisplayMessage(response.data.message);
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          setIsReset(false);
        });
    }
    else {
      setIsReset(false);
      setDisplayMessage("Child field cannot be blank");
    }
  }, [PTInput.ResetChild, isReset])

  useEffect(() => {
    setState({
      token: localStorage.getItem("authToken") || null,
      userType: localStorage.getItem("userType") || null,
      firstName: localStorage.getItem("firstName") || null,
      children: localStorage.getItem("children") ? JSON.parse(localStorage.getItem("children")) : [],
      loading: false
    });
  }, [localStorage.getItem("authToken"), localStorage.getItem("userType"), localStorage.getItem("firstName"), localStorage.getItem("children")]);

  useEffect(() => {
    // Generate 5 random test scores for each subject
    if(JSON.parse(localStorage.getItem("children")).length != 0){
    fetchDashboardData(state.children[0][0]);
  }}, []);




  const [showModalExamResults, setShowModalExamResults] = useState(false);
  const [examData, setExamData] = useState(null);
  const [userValues, setUserValues] = useState("");

  const handleLinkClick = (examId) => {
    fetchExamData(examId);
    setShowModalExamResults(true);
  };

  const handleHideModalExamResults = () => {
    setShowModalExamResults(false);
    setUserValues("");
  }
  const fetchExamData = (examId) => {
    const csrftoken = Cookies.get("csrftoken");
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;

    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("authToken")}`,
      },
    };

    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/practice-test-result/${examId}/`, config)
      .then((response) => {
        setUserValues(response.data);
        console.log(userValues)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disableCopying = (e) => {
    e.preventDefault();
    return false;
  };


  return (
    <div>
  {message && <SuccessMessage message={message} />}
  {success == true && <SuccessMessage message={props.message} />}
  {state.userType === "parent" && state.children && state.children.length === 0 && <ChildSignUp />}
   <Tabs
      style={{ margin: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", padding: "20px" }}
      activeKey={activeTab}
      onSelect={handleTabSelect}
    >
    {namesArray.map((name, index) => (
      <Tab eventKey={index} title={name} key={index}>
        <Card style={{
                    margin: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out"
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}>
          <Row className="mt-4" style={{ margin: "20px" }}>
            <Col md="3">
              <Card className="text-center" style={{ borderRadius: "8px" }}>
                <Card.Body>
                  <Card.Title>Maths</Card.Title>
                  <Card.Text>
                    Average Percentage Score of last {scores.Maths.total_length - 1} tests: {scores.Maths.average}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card className="text-center" style={{ borderRadius: "8px" }}>
                <Card.Body>
                  <Card.Title>English</Card.Title>
                  <Card.Text>
                    Average Percentage Score of last {scores.English.total_length - 1} tests: {scores.English.average}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card className="text-center" style={{ borderRadius: "8px"}}>
                <Card.Body>
                  <Card.Title>VR</Card.Title>
                  <Card.Text>
                    Average Percentage Score of last {scores.VR.total_length - 1} tests: {scores.VR.average}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md="3">
              <Card className="text-center" style={{ borderRadius: "8px" }}>
                <Card.Body>
                  <Card.Title>NVR</Card.Title>
                  <Card.Text>
                    Average Percentage Score of last {scores.NVR.total_length - 1} tests: {scores.NVR.average}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card>
        {showChildForm === false && (
          <Row className="mt-4" style={{ margin: "20px" }}>
            {state.children != "" && (
              <Card style={{
                alignItems: "center", 
                borderRadius: "8px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s ease-in-out"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "none";
              }}>
                <Card.Title style={{marginTop: "10px"}}>View progress and reports</Card.Title>
                <Card.Body>
                  <Form.Group controlId="child">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Label style={{ marginRight: "10px" }}>View how {name} is performing in subject</Form.Label>
                      <Form.Select
                        style={{
                          fontSize: "14px",
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          width: "150px",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        onChange={(e) => onInputChange(e)}
                        name="subject"
                        value={PTInput.subject}
                      >
                        <option value=""></option>
                        <option value="Maths">Maths</option>
                        <option value="English">English</option>
                        <option value="VR">VR</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            )}
          </Row>
        )}
        {showChildForm === false && (
          <Row>
            {PTInput.subject != "" && dashboardData && (
              <>
              {(dashboardData[PTInput.subject].length > 1) ? (
                <>
                <Col md={4} style={{ marginLeft: "10px" }}>
                  <Card style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out"
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}>
                    <Card.Header ><strong>Last 5 Exams Performance based on Date </strong></Card.Header>
                    <Card.Body>
                      <ResponsiveContainer className="mt-4" width="100%" height={400}>
                        <BarChart width={300} height={400} data={barChartDate} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                          <XAxis dataKey="date" />
                          <YAxis domain={[0, 100]} />
                          <Tooltip />
                          <Bar dataKey="percentage" fill="#8884d8">
                            <LabelList dataKey="percentage" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out"
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}>
                    <Card.Header><strong>Last 5 Exams Performance based on Topic</strong></Card.Header>
                    <Card.Body>
                      <ResponsiveContainer className="mt-4" width="100%" height={400}>
                        <BarChart
                          width={500}
                          height={400}
                          data={barChartTopic}
                          margin={{ top: 18, right: 10, left: 0, bottom: 20 }}
                        >
                          <XAxis dataKey="topic" interval={0} angle={-30} dy={10} tick={{ fontSize: 12 }} />
                          <YAxis domain={[0, 100]} />
                          <Tooltip />
                          <Bar dataKey="percentage" fill="#8884d8">
                            <LabelList dataKey="percentage" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out"
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "none";
                  }}>
                    <Card.Header><strong>Previous Exam Results</strong></Card.Header>
                    <Card.Body>
  <ul style={{ paddingLeft: 0, listStyleType: "none" }}>
    {dashboardData &&
      dashboardData[PTInput.subject].map((exam) => (
        exam.id !== undefined && (
          <li key={exam.id} style={{ marginBottom: "10px" }}>
            <Link
              to="#"
              onClick={() => handleLinkClick(exam.id)}
              style={{
                textDecoration: "none",
                color: "#007bff",
                display: "flex",
                alignItems: "center",
                padding: "2px",
                borderRadius: "4px",
                transition: "background-color 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#f1f1f1";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <i className="fas fa-file-alt"></i>
              </div>
              <div>
                <strong>{PTInput.subject} Practice Test</strong> - {formatDateTime(exam.test_date)}
              </div>
            </Link>
          </li>
        )
      ))}
  </ul>
</Card.Body>

                  </Card>
                </Col>
              </>) :
              (<>
              <div className="d-flex justify-content-center align-items-center" style={{ height: "10vh", marginTop: "10vh"}}>
          <div className="card text-center" style={{  padding: "20px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <div className="card-body">
              <h5 className="card-title">Metrics Not Yet Available</h5>
              <p className="card-text">You can see the Progress and Reports after {name} writes atleast one {PTInput.subject} exam. </p>
            </div>
          </div>
        </div>
              </>)}
                
              </>
            )}
          </Row>
        )}
      </Tab>
    ))}
    <Tab eventKey="+" title="Add another child"></Tab>
  </Tabs>
  {showChildForm && (
    <Card style={{
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease-in-out"
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 123, 255, 0.5)";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.boxShadow = "none";
    }}>
      <ChildSignUp />
    </Card>
  )}
  <Modal show={showModalExamResults} onHide={handleHideModalExamResults} size="xl">
    <div
      className="overflow-auto"
      style={{ userSelect: "none" }}
      onContextMenu={disableCopying}
      onCopy={disableCopying}
      onCut={disableCopying}
      onPaste={disableCopying}
    >
      <Modal.Header closeButton>
        <Card.Title className="mt-2" as="h2">
          Test Results
        </Card.Title>
      </Modal.Header>
      <Modal.Body>
        {userValues && (
          <>
            <Row className="mb-3">
              <Col>
                <Card.Header style={{ backgroundColor: "#e9ecef", fontWeight: "bold" }}>Date and Time: {userValues.test_date && new Date(userValues.test_date).toLocaleString()}</Card.Header>
              </Col>
              <Col>
                <Card.Header style={{ backgroundColor: "#e9ecef", fontWeight: "bold" }}>Score: {userValues.score} / {userValues.total_score} ({((userValues.score / userValues.total_score) * 100).toFixed(1)}%)</Card.Header>
              </Col>
            </Row>
            <Row>
              <Card>
                <div>
                  <Card.Body>
                  <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Description</th>
                    <th>Your Answers</th>
                    <th>Correct Answer</th>
                    <th>Answer Explanation</th>
                  </tr>
                </thead>
                <tbody>
                  {userValues && userValues.questions && userValues.questions.map((question, index) => {
                    if (question.question_type === 'MP') {
                      return (
                        <>
                          <tr key={`main-${index}`}>
                            <td>{index + 1}</td>
                            <td colSpan="4"><MathJaxComponent>{question.questionDescription}</MathJaxComponent></td>
                          </tr>
                          {question.fib_subquestions.map((subQuestion, subIndex) => {
                            const subUserAnswer = userValues.user_answer[subQuestion.id];
                            const subCorrectAnswer = subQuestion.correctAnswer;
                            const subIsMatch = subUserAnswer === subCorrectAnswer;
                            const subClassName = subIsMatch ? 'table-success' : 'table-danger';
                            return (
                              <>
                              <tr key={`fib-sub-${index}-${subIndex}`} className={subClassName}>
                                <td>{index + 1}.{subIndex + 1}</td>
                                <td><MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent></td>
                                <td>
                                  <p>{subUserAnswer}</p>
                                </td>
                                <td>
                                  <p>{subCorrectAnswer}</p>
                                </td>
                                <td>
                                  {subQuestion.answerExplanation && (<Button onClick={() => toggleRow(`fib-sub-${index}-${subIndex}`)}>Explanation</Button>)}
                                </td>
                              </tr>
                                <tr>
                                  <td colSpan="5" style={{ padding: 0, border: 0 }}>
                                    <Collapse in={expandedRows[`fib-sub-${index}-${subIndex}`]}>
                                      <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                                      <MathJaxComponent>{subQuestion.answerExplanation}</MathJaxComponent>
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          {question.mc_subquestions.map((subQuestion, subIndex) => {
                            const subUserAnswer = userValues.user_answer[subQuestion.id];
                            const subCorrectAnswer = subQuestion.correctAnswer;
                            const subIsMatch = subUserAnswer === subCorrectAnswer;
                            const subClassName = subIsMatch ? 'table-success' : 'table-danger';
                            return (
                              <>
                              <tr key={`mc-sub-${index}-${subIndex}`} className={subClassName}>
                                <td>{index + 1}.{subIndex + 1 + question.fib_subquestions.length}</td>
                                <td><MathJaxComponent>{subQuestion.questionDescription}</MathJaxComponent></td>
                                <td>
                                  <p>{subUserAnswer}</p>
                                </td>
                                <td>
                                  <p>{subCorrectAnswer}</p>
                                </td>
                                <td>
                                  {subQuestion.answerExplanation && (<Button onClick={() => toggleRow(`mc-sub-${index}-${subIndex}`)}>Explanation</Button>)}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="5" style={{ padding: 0, border: 0 }}>
                                  <Collapse in={expandedRows[`mc-sub-${index}-${subIndex}`]}>
                                    <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                                      <MathJaxComponent>{subQuestion.answerExplanation}</MathJaxComponent>
                                    </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      );
                    }

                    var userAnswer = ''
                    try {
                      userAnswer = userValues.user_answer[question.id][question.id];
                    } catch (error) {
                      userAnswer = userValues.user_answer != null ? userValues.user_answer[index] : "-";
                    }
                    // const userAnswer = userValues.user_answer[question.id][question.id];
                    const correctAnswer = question.correctAnswer;
                    const isMatch = userAnswer === correctAnswer;
                    const className = isMatch ? 'table-success' : 'table-danger';
                    return (
                      <>
                        <tr key={index} className={className}>
                          <td>{index + 1}</td>
                          <td><MathJaxComponent>{question.questionDescription}</MathJaxComponent></td>
                          <td>
                            <p>{userAnswer}</p>
                          </td>
                          <td>
                            <p>{correctAnswer}</p>
                          </td>
                          <td>
                            {question.answerExplanation && (<Button onClick={() => toggleRow(index)}>Explanation</Button>)}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="5" style={{ padding: 0, border: 0 }}>
                            <Collapse in={expandedRows[index]}>
                              <div style={{ padding: "10px", borderTop: "1px solid #dee2e6", backgroundColor: "#f8f9fa" }}>
                                <MathJaxComponent>{question.answerExplanation}</MathJaxComponent>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>


              </Table>
                  </Card.Body>
                </div>
              </Card>
            </Row>
          </>
        )}
      </Modal.Body>
    </div>
  </Modal>
</div>


  )
}

export default withLoader(Home);
