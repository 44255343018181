import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

const Forbidden = () => {
  return (
    <Container fluid className="d-flex justify-content-center align-items-start" style={{ backgroundColor: "#f8f9fa", height: "100vh", paddingTop: "5%" }}>
      <Row className="justify-content-center w-100">
        <Col md={8}>
          <Card className="text-center shadow-lg">
            <Card.Header as="h4" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Access Denied</Card.Header>
            <Card.Body>
              <Card.Title className="mb-4" style={{ color: "#dc3545" }}>403 - Forbidden</Card.Title>
              <Card.Text>
                You do not have permission to access this page.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};


export default Forbidden;
